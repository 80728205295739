import React, { createContext, useCallback, useContext, useState } from "react";

import { ConfirmDialog, ConfirmDialogOptions } from "../../components/ConfirmDialog/ConfirmDialog";

const DEFAULT_OPTIONS: ConfirmDialogOptions = {
  title: "Are you sure?",
  description: "",
  confirmationText: "Ok",
  cancellationText: "Cancel",
};

export type ConfirmContextValue = (decorationOptions?: ConfirmDialogOptions) => Promise<void>;

const ConfirmContext = createContext<ConfirmContextValue>(async () => {
  // noop
});

/**
 * @deprecated
 */
export type ConfirmProviderProps = React.PropsWithChildren<{ defaultOptions?: ConfirmDialogOptions }>;

/**
 * @deprecated use ConfirmProvider from ui/components/ConfirmProviderNew instead
 */
export const ConfirmProvider: React.FC<ConfirmProviderProps> = ({ children, defaultOptions }) => {
  const [options, setOptions] = useState({ ...DEFAULT_OPTIONS, ...defaultOptions });

  const [resolveReject, setResolveReject] = useState<(() => void)[]>([]);

  const [resolve, reject] = resolveReject;

  const confirm = useCallback<ConfirmContextValue>(
    (decorationOptions?: ConfirmDialogOptions) => {
      return new Promise((resolve, reject) => {
        const confirmationButtonProps = {
          ...(defaultOptions?.confirmationButtonProps || DEFAULT_OPTIONS.confirmationButtonProps),
          ...(decorationOptions?.confirmationButtonProps || {}),
        };

        const cancellationButtonProps = {
          ...(defaultOptions?.cancellationButtonProps || DEFAULT_OPTIONS.cancellationButtonProps),
          ...(decorationOptions?.cancellationButtonProps || {}),
        };

        setOptions({
          ...DEFAULT_OPTIONS,
          ...defaultOptions,
          ...decorationOptions,
          confirmationButtonProps,
          cancellationButtonProps,
        });

        setResolveReject([resolve, reject]);
      });
    },
    [defaultOptions]
  );

  const handleClose = useCallback(() => {
    setResolveReject([]);
  }, []);

  const handleCancel = useCallback(() => {
    reject();
    handleClose();
  }, [handleClose, reject]);

  const handleConfirm = useCallback(() => {
    resolve();
    handleClose();
  }, [handleClose, resolve]);

  return (
    <ConfirmContext.Provider value={confirm}>
      {children}

      <ConfirmDialog
        isOpen={resolveReject.length === 2}
        options={options}
        onClose={handleClose}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </ConfirmContext.Provider>
  );
};

export const useConfirm = (): ConfirmContextValue => useContext(ConfirmContext);
