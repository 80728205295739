import clsx from "clsx";
import React from "react";

import * as styles from "./InputRadio.module.css";
import { Radio, RadioProps } from "./Radio";

export type InputRadioProps = RadioProps & {
  widePadding?: boolean;
  nowrap?: boolean;
  "data-testid"?: string;
};

export const InputRadio: React.FC<InputRadioProps> = ({
  disabled,
  completed,
  widePadding,
  className,
  checked,
  children,
  nowrap = true,
  ...props
}) => (
  <label
    className={clsx(
      styles.container,
      disabled && styles.disabled,
      completed && styles.completed,
      nowrap && styles.nowrap,
      widePadding && styles.widePadding,
      className
    )}
    data-testid={props["data-testid"]}
  >
    <Radio disabled={disabled} completed={completed} checked={checked} {...props} data-testid={undefined} />
    {children && <span className={styles.label}>{children}</span>}
  </label>
);
