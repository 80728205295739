import {
  // eslint-disable-next-line no-restricted-imports
  TextInnerLinkExternal as CommonTextInnerLinkExternal,
  TextInnerLinkExternalProps as CommonTextInnerLinkExternalProps,
} from "@mgdx/ui/components/TextInnerLink";
import React, { useCallback } from "react";

import { useIsInMccmApp } from "../../hooks/useIsInMccmApp";
import { useAppStatus } from "../../providers/AppStatusProvider";
import { callYqbExternalBrowser } from "../../utils/app-bridge/publisher";
import { EXTERNAL_LINK_CONFIRMATION_TEXT } from "../../utils/constants/externalLink";

export type TextInnerLinkExternalProps = Omit<CommonTextInnerLinkExternalProps, "target">;

export const TextInnerLinkExternal: React.FC<TextInnerLinkExternalProps> = ({ href, ...props }) => {
  const { isInApp, isInLineApp } = useAppStatus();
  const isInMccmApp = useIsInMccmApp();

  const onClickInLineApp = useCallback(() => {
    const result = window.confirm(EXTERNAL_LINK_CONFIRMATION_TEXT + "\n" + href);

    if (result) {
      window.open(href, "_blank");
    }
  }, [href]);

  const onClickInMccmApp = useCallback(() => {
    if (!href) return;

    const url = new URL(href, process.env.MGDX_YQB_URL);
    callYqbExternalBrowser(url.toString());
  }, [href]);

  return isInLineApp ? (
    <CommonTextInnerLinkExternal {...props} target="_self" onClick={onClickInLineApp} />
  ) : isInMccmApp ? (
    <CommonTextInnerLinkExternal {...props} target="_self" onClick={onClickInMccmApp} />
  ) : (
    <CommonTextInnerLinkExternal {...props} href={href} target={isInApp ? "_self" : "_blank"} />
  );
};
