/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PatientMedicineAlarmSettingStatus {
  Enabled = "enabled",
  Disabled = "disabled",
  Deleted = "deleted",
}

export function PatientMedicineAlarmSettingStatusFromJSON(json: any): PatientMedicineAlarmSettingStatus {
  return PatientMedicineAlarmSettingStatusFromJSONTyped(json, false);
}

export function PatientMedicineAlarmSettingStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientMedicineAlarmSettingStatus {
  return json as PatientMedicineAlarmSettingStatus;
}

export function PatientMedicineAlarmSettingStatusToJSON(value?: PatientMedicineAlarmSettingStatus | null): any {
  return value as any;
}
