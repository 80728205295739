/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyTemporaryCalendarTypename = "PharmacyTemporaryCalendar" as const;

/**
 *
 * @export
 * @interface PharmacyTemporaryCalendar
 */
export interface PharmacyTemporaryCalendar {
  /**
   *
   * @type {boolean}
   * @memberof PharmacyTemporaryCalendar
   */
  counselingClosedAll?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyTemporaryCalendar
   */
  counselingReceptionHours?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PharmacyTemporaryCalendar
   */
  date: string;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyTemporaryCalendar
   */
  dispensingClosedAll?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyTemporaryCalendar
   */
  dispensingReceptionHours?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof PharmacyTemporaryCalendar
   */
  pharmacyId: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyTemporaryCalendar
   */
  readonly __typename?: string;
}

export function PharmacyTemporaryCalendarFromJSON(json: any): PharmacyTemporaryCalendar {
  return PharmacyTemporaryCalendarFromJSONTyped(json, false);
}

export function PharmacyTemporaryCalendarFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyTemporaryCalendar {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyTemporaryCalendarTypename,

    counselingClosedAll: !exists(json, "counseling_closed_all") ? undefined : json["counseling_closed_all"],
    counselingReceptionHours: !exists(json, "counseling_reception_hours")
      ? undefined
      : json["counseling_reception_hours"],
    date: json["date"],
    dispensingClosedAll: !exists(json, "dispensing_closed_all") ? undefined : json["dispensing_closed_all"],
    dispensingReceptionHours: !exists(json, "dispensing_reception_hours")
      ? undefined
      : json["dispensing_reception_hours"],
    pharmacyId: json["pharmacy_id"],
  };
}

export function PharmacyTemporaryCalendarToJSON(object?: PharmacyTemporaryCalendar | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    counseling_closed_all: value.counselingClosedAll,
    counseling_reception_hours: value.counselingReceptionHours,
    date: value.date,
    dispensing_closed_all: value.dispensingClosedAll,
    dispensing_reception_hours: value.dispensingReceptionHours,
    pharmacy_id: value.pharmacyId,
  };
}
