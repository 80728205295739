import clsx from "clsx";
import React from "react";

import * as styles from "./SvgIcon.module.css";

export const sizes = ["xs", "s", "m", "xm", "xxm", "l"] as const;

export const variants = ["primary", "secondary", "tertiary", "text_link", "key"] as const;

export type Props = React.SVGProps<SVGSVGElement> & {
  size?: (typeof sizes)[number];
  variant?: (typeof variants)[number];
};

const DropdownIcon: React.FC<Props> = ({ className, size = "m", variant, ...props }) => {
  const fill = props.fill ?? "inherit";
  return (
    <svg
      fill={fill}
      role={props["aria-label"] ? "img" : undefined}
      aria-hidden={props["aria-hidden"] ?? !props["aria-label"] ? true : undefined}
      className={clsx(
        size === "xs" && styles.iconSizeXS,
        size === "s" && styles.iconSizeS,
        size === "m" && styles.iconSizeM,
        size === "xm" && styles.iconSizeXM,
        size === "xxm" && styles.iconSizeXXM,
        size === "l" && styles.iconSizeL,
        variant === "primary" && styles.iconPrimary,
        variant === "secondary" && styles.iconSecondary,
        variant === "tertiary" && styles.iconTertiary,
        variant === "text_link" && styles.iconLink,
        variant === "key" && styles.iconKey,
        className
      )}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.34519 10.2716C7.53866 9.80455 7.99446 9.5 8.50004 9.5H15.5C16.0056 9.5 16.4614 9.80455 16.6549 10.2716C16.8484 10.7387 16.7414 11.2764 16.3839 11.6339L12.8839 15.1339C12.3958 15.622 11.6043 15.622 11.1162 15.1339L7.61615 11.6339C7.25866 11.2764 7.15171 10.7387 7.34519 10.2716Z"
      />
    </svg>
  );
};

export default DropdownIcon;
