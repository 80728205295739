/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutCounselingReserverTypeRequestBodyTypename = "PutCounselingReserverTypeRequestBody" as const;

/**
 *
 * @export
 * @interface PutCounselingReserverTypeRequestBody
 */
export interface PutCounselingReserverTypeRequestBody {
  /**
   *
   * @type {string}
   * @memberof PutCounselingReserverTypeRequestBody
   */
  reserverType: PutCounselingReserverTypeRequestBodyReserverTypeEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutCounselingReserverTypeRequestBody
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PutCounselingReserverTypeRequestBodyReserverTypeEnum {
  Principal = "principal",
  Proxy = "proxy",
  Either = "either",
}

export function PutCounselingReserverTypeRequestBodyFromJSON(json: any): PutCounselingReserverTypeRequestBody {
  return PutCounselingReserverTypeRequestBodyFromJSONTyped(json, false);
}

export function PutCounselingReserverTypeRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutCounselingReserverTypeRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutCounselingReserverTypeRequestBodyTypename,

    reserverType: json["reserver_type"],
  };
}

export function PutCounselingReserverTypeRequestBodyToJSON(object?: PutCounselingReserverTypeRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    reserver_type: value.reserverType,
  };
}
