import { Heading } from "@mgdx/ui/components/Heading";
import clsx from "clsx";
import React from "react";

import { useAppStatus } from "../../providers/AppStatusProvider";
import * as styles from "./PageTitle.module.css";

export type PageTitleProps = React.PropsWithChildren<{ className?: string }>;

export const PageTitle: React.FC<PageTitleProps> = ({ className, children }) => {
  const { isInLineApp } = useAppStatus();

  if (isInLineApp) {
    return null;
  }
  return (
    <Heading level={1} withPaddingBottom={true} className={clsx(styles.title, className)}>
      {children}
    </Heading>
  );
};
