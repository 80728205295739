import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

import { ErrorReporter } from "./createErrorHandler";
import { ErrorFallback } from "./ErrorFallback";
import { SentryErrorBoundary } from "./SentryErrorBoundary";

export type ErrorBoundaryProps = React.PropsWithChildren<{
  errorHandlerReport: ErrorReporter;
}>;

export const BaseErrorBoundary: React.FC<ErrorBoundaryProps> = ({ errorHandlerReport, children }) => (
  <ReactErrorBoundary onError={(error) => errorHandlerReport(error)} FallbackComponent={ErrorFallback}>
    {children}
  </ReactErrorBoundary>
);

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ errorHandlerReport, children }) => {
  if (process.env.ENABLE_SENTRY !== "true") {
    return <BaseErrorBoundary errorHandlerReport={errorHandlerReport}>{children}</BaseErrorBoundary>;
  }
  return <SentryErrorBoundary errorHandlerReport={errorHandlerReport}>{children}</SentryErrorBoundary>;
};
