/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  GetMedicineAlarmSettingsResponse,
  GetMedicineAlarmSettingsResponseFromJSON,
  GetMedicineAlarmSettingsResponseToJSON,
  PatientMedicineAlarmSetting,
  PatientMedicineAlarmSettingFromJSON,
  PatientMedicineAlarmSettingToJSON,
  PatientMedicineAlarmSettingRegistrable,
  PatientMedicineAlarmSettingRegistrableFromJSON,
  PatientMedicineAlarmSettingRegistrableToJSON,
  PostMedicineAlarmsRequestBody,
  PostMedicineAlarmsRequestBodyFromJSON,
  PostMedicineAlarmsRequestBodyToJSON,
} from "../models";

export interface GetMedicineAlarmSettingRequest {
  chainId: number;
  dispenseRecordId?: number;
  otcMedicineId?: number;
  familyAccountId?: number;
  date?: string;
}

export interface GetMedicineAlarmSettingsRequest {
  chainId: number;
  familyAccountIds?: Array<number>;
}

export interface PostMedicineAlarmSettingRequest {
  chainId: number;
  patientMedicineAlarmSettingRegistrable: PatientMedicineAlarmSettingRegistrable;
  dispenseRecordId?: number;
  otcMedicineId?: number;
  familyAccountId?: number;
  maxAccountAlarmsCount?: number;
}

export interface PostMedicineAlarmsRequest {
  postMedicineAlarmsRequestBody: PostMedicineAlarmsRequestBody;
}

export interface PutMedicineAlarmSettingRequest {
  medicineAlarmSettingId: string;
  chainId: number;
  patientMedicineAlarmSettingRegistrable: PatientMedicineAlarmSettingRegistrable;
  maxAccountAlarmsCount?: number;
}

/**
 *
 */
export class MedicineAlarmApi extends runtime.BaseAPI {
  /**
   * Get medicine alarm setting
   */
  async getMedicineAlarmSettingRaw(
    requestParameters: GetMedicineAlarmSettingRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicineAlarmSetting>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getMedicineAlarmSetting."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.dispenseRecordId !== undefined) {
      queryParameters["dispense_record_id"] = requestParameters.dispenseRecordId;
    }

    if (requestParameters.otcMedicineId !== undefined) {
      queryParameters["otc_medicine_id"] = requestParameters.otcMedicineId;
    }

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    if (requestParameters.date !== undefined) {
      queryParameters["date"] = requestParameters.date;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/medicine-alarm-setting`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMedicineAlarmSettingFromJSON(jsonValue));
  }

  /**
   * Get medicine alarm setting
   */
  async getMedicineAlarmSetting(
    requestParameters: GetMedicineAlarmSettingRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicineAlarmSetting> {
    return promiseRetry(
      (retry) =>
        this.getMedicineAlarmSettingRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get medicine alarm settings
   */
  async getMedicineAlarmSettingsRaw(
    requestParameters: GetMedicineAlarmSettingsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetMedicineAlarmSettingsResponse>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getMedicineAlarmSettings."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountIds) {
      queryParameters["family_account_ids"] = requestParameters.familyAccountIds;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/medicine-alarm-settings`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetMedicineAlarmSettingsResponseFromJSON(jsonValue));
  }

  /**
   * Get medicine alarm settings
   */
  async getMedicineAlarmSettings(
    requestParameters: GetMedicineAlarmSettingsRequest,
    initOverrides?: RequestInit
  ): Promise<GetMedicineAlarmSettingsResponse> {
    return promiseRetry(
      (retry) =>
        this.getMedicineAlarmSettingsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Post medicine alarm setting
   */
  async postMedicineAlarmSettingRaw(
    requestParameters: PostMedicineAlarmSettingRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicineAlarmSetting>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling postMedicineAlarmSetting."
      );
    }

    if (
      requestParameters.patientMedicineAlarmSettingRegistrable === null ||
      requestParameters.patientMedicineAlarmSettingRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "patientMedicineAlarmSettingRegistrable",
        "Required parameter requestParameters.patientMedicineAlarmSettingRegistrable was null or undefined when calling postMedicineAlarmSetting."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.dispenseRecordId !== undefined) {
      queryParameters["dispense_record_id"] = requestParameters.dispenseRecordId;
    }

    if (requestParameters.otcMedicineId !== undefined) {
      queryParameters["otc_medicine_id"] = requestParameters.otcMedicineId;
    }

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    if (requestParameters.maxAccountAlarmsCount !== undefined) {
      queryParameters["max_account_alarms_count"] = requestParameters.maxAccountAlarmsCount;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/medicine-alarm-settings`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PatientMedicineAlarmSettingRegistrableToJSON(requestParameters.patientMedicineAlarmSettingRegistrable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMedicineAlarmSettingFromJSON(jsonValue));
  }

  /**
   * Post medicine alarm setting
   */
  async postMedicineAlarmSetting(
    requestParameters: PostMedicineAlarmSettingRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicineAlarmSetting> {
    return promiseRetry(
      (retry) =>
        this.postMedicineAlarmSettingRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Post medicine alarms
   */
  async postMedicineAlarmsRaw(
    requestParameters: PostMedicineAlarmsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.postMedicineAlarmsRequestBody === null ||
      requestParameters.postMedicineAlarmsRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postMedicineAlarmsRequestBody",
        "Required parameter requestParameters.postMedicineAlarmsRequestBody was null or undefined when calling postMedicineAlarms."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/medicine-alarms`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostMedicineAlarmsRequestBodyToJSON(requestParameters.postMedicineAlarmsRequestBody),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Post medicine alarms
   */
  async postMedicineAlarms(requestParameters: PostMedicineAlarmsRequest, initOverrides?: RequestInit): Promise<void> {
    await this.postMedicineAlarmsRaw(requestParameters, initOverrides);
  }

  /**
   * Put medicine alarm setting
   */
  async putMedicineAlarmSettingRaw(
    requestParameters: PutMedicineAlarmSettingRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicineAlarmSetting>> {
    if (requestParameters.medicineAlarmSettingId === null || requestParameters.medicineAlarmSettingId === undefined) {
      throw new runtime.RequiredError(
        "medicineAlarmSettingId",
        "Required parameter requestParameters.medicineAlarmSettingId was null or undefined when calling putMedicineAlarmSetting."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putMedicineAlarmSetting."
      );
    }

    if (
      requestParameters.patientMedicineAlarmSettingRegistrable === null ||
      requestParameters.patientMedicineAlarmSettingRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "patientMedicineAlarmSettingRegistrable",
        "Required parameter requestParameters.patientMedicineAlarmSettingRegistrable was null or undefined when calling putMedicineAlarmSetting."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.maxAccountAlarmsCount !== undefined) {
      queryParameters["max_account_alarms_count"] = requestParameters.maxAccountAlarmsCount;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/medicine-alarm-settings/{medicine_alarm_setting_id}`
          .replace(
            `{${"medicine_alarm_setting_id"}}`,
            encodeURIComponent(String(requestParameters.medicineAlarmSettingId))
          )
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PatientMedicineAlarmSettingRegistrableToJSON(requestParameters.patientMedicineAlarmSettingRegistrable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMedicineAlarmSettingFromJSON(jsonValue));
  }

  /**
   * Put medicine alarm setting
   */
  async putMedicineAlarmSetting(
    requestParameters: PutMedicineAlarmSettingRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicineAlarmSetting> {
    return promiseRetry(
      (retry) =>
        this.putMedicineAlarmSettingRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
