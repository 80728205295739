/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutPharmacyTemporaryCalendarRequestBodyTypename = "PutPharmacyTemporaryCalendarRequestBody" as const;

/**
 *
 * @export
 * @interface PutPharmacyTemporaryCalendarRequestBody
 */
export interface PutPharmacyTemporaryCalendarRequestBody {
  /**
   *
   * @type {boolean}
   * @memberof PutPharmacyTemporaryCalendarRequestBody
   */
  counselingClosedAll?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof PutPharmacyTemporaryCalendarRequestBody
   */
  counselingHours?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof PutPharmacyTemporaryCalendarRequestBody
   */
  dispensingClosedAll?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof PutPharmacyTemporaryCalendarRequestBody
   */
  dispensingHours?: Array<string>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutPharmacyTemporaryCalendarRequestBody
   */
  readonly __typename?: string;
}

export function PutPharmacyTemporaryCalendarRequestBodyFromJSON(json: any): PutPharmacyTemporaryCalendarRequestBody {
  return PutPharmacyTemporaryCalendarRequestBodyFromJSONTyped(json, false);
}

export function PutPharmacyTemporaryCalendarRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutPharmacyTemporaryCalendarRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutPharmacyTemporaryCalendarRequestBodyTypename,

    counselingClosedAll: !exists(json, "counseling_closed_all") ? undefined : json["counseling_closed_all"],
    counselingHours: !exists(json, "counseling_hours") ? undefined : json["counseling_hours"],
    dispensingClosedAll: !exists(json, "dispensing_closed_all") ? undefined : json["dispensing_closed_all"],
    dispensingHours: !exists(json, "dispensing_hours") ? undefined : json["dispensing_hours"],
  };
}

export function PutPharmacyTemporaryCalendarRequestBodyToJSON(
  object?: PutPharmacyTemporaryCalendarRequestBody | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    counseling_closed_all: value.counselingClosedAll,
    counseling_hours: value.counselingHours,
    dispensing_closed_all: value.dispensingClosedAll,
    dispensing_hours: value.dispensingHours,
  };
}
