import { toastError } from "@mgdx/ui/components/Toast";
import { apiErrorHandler, errorHandlerReport } from "@mgdx-libs/error-handler";
import React, { useCallback, useEffect, useState } from "react";

import { useUpdateAgreementMutation } from "../../hooks/patient/useUpdateAgreementMutation";
import { AgreementNewTermsConfirmDialog } from "./AgreementNewTermsConfirmDialog";
import { AgreementNewTermsReasonDialog } from "./AgreementNewTermsReasonDialog";
import { AgreementNewTermsWithdrawDialog } from "./AgreementNewTermsWithdrawDialog";

export const AgreementNewTermsMain = () => {
  const [dialogType, setDialogType] = useState<"confirm" | "reason" | "withdraw" | "agreed">("confirm");

  const { mutate, isLoading, isSuccess, isError, error } = useUpdateAgreementMutation();

  const onClickAgreement = useCallback(() => {
    mutate();
  }, [mutate]);

  const onClickShowReason = useCallback(() => {
    setDialogType("reason");
  }, []);

  const onClickShowWithdraw = useCallback(() => {
    setDialogType("withdraw");
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setDialogType("agreed");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      // APIのエラーが発生した場合は同意ダイアログを消しサービス利用できるようにする
      setDialogType("agreed");
    }
  }, [isError]);

  useEffect(() => {
    if (error) {
      toastError("同意に失敗しました");

      (async () => {
        if (error instanceof DOMException) {
          try {
            throw await apiErrorHandler(error);
          } catch (e) {
            await errorHandlerReport(e as Error);
          }
        } else if (error instanceof Error) {
          await errorHandlerReport(error);
        }
      })();
    }
  }, [error]);

  if (dialogType === "confirm") {
    return (
      <AgreementNewTermsConfirmDialog
        isLoading={isLoading}
        onClickAgreement={onClickAgreement}
        onClickShowReason={onClickShowReason}
      />
    );
  }

  if (dialogType === "reason") {
    return (
      <AgreementNewTermsReasonDialog
        isLoading={isLoading}
        onClickAgreement={onClickAgreement}
        onClickShowWithdraw={onClickShowWithdraw}
      />
    );
  }

  if (dialogType === "withdraw") {
    return <AgreementNewTermsWithdrawDialog onClickShowReason={onClickShowReason} />;
  }

  return null;
};
