import clsx from "clsx";
import { default as lottie } from "lottie-web/build/player/lottie_light";
import React, { useEffect, useRef } from "react";

import animationData from "./loading.json";
import * as styles from "./PreloaderForMccm.module.css";

export type PreloaderForMccmProps = {
  className?: string;
};

const Loading: React.FC = () => {
  const animationContainer = useRef(null);

  useEffect(() => {
    if (!animationContainer.current) return;

    const animation = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData,
    });

    return () => {
      animation.destroy();
    };
  }, []);

  return <div ref={animationContainer} className={styles.loading} />;
};

export const PreloaderForMccm: React.FC<PreloaderForMccmProps> = ({ className }) => {
  return (
    <div className={clsx(styles.container, className)}>
      <Loading />
    </div>
  );
};
