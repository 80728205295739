// extracted by mini-css-extract-plugin
export var caution = "Message-module--caution--1d266";
export var form = "Message-module--form--0ae1c";
export var info = "Message-module--info--66af3";
export var infoCenter = "Message-module--infoCenter--e35e9";
export var list = "Message-module--list--109b1";
export var listMessage = "Message-module--listMessage--1ca8c";
export var markerList = "Message-module--markerList--6f634";
export var message = "Message-module--message--1988d";
export var multiline = "Message-module--multiline--363f3";
export var notice = "Message-module--notice--15f74";
export var noticeCenter = "Message-module--noticeCenter--1d38d";
export var responsive = "Message-module--responsive--de94c";
export var root = "Message-module--root--5e280";
export var strong = "Message-module--strong--c085a";
export var strongLeft = "Message-module--strongLeft--fc76b";
export var weak = "Message-module--weak--a6dbd";
export var weakCenter = "Message-module--weakCenter--bd944";
export var withBackground = "Message-module--withBackground--7d6df";