/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorExternalID,
  PharmacyCounselorExternalIDFromJSON,
  PharmacyCounselorExternalIDFromJSONTyped,
  PharmacyCounselorExternalIDToJSON,
} from "./";

export const PharmacyCounselorMedicineNotebookAutoLinkPatientTypename =
  "PharmacyCounselorMedicineNotebookAutoLinkPatient" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorMedicineNotebookAutoLinkPatient
 */
export interface PharmacyCounselorMedicineNotebookAutoLinkPatient {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAutoLinkPatient
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAutoLinkPatient
   */
  externalId?: string;
  /**
   *
   * @type {PharmacyCounselorExternalID}
   * @memberof PharmacyCounselorMedicineNotebookAutoLinkPatient
   */
  externalIdV2?: PharmacyCounselorExternalID;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorMedicineNotebookAutoLinkPatient
   */
  familyAccountId?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAutoLinkPatient
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAutoLinkPatient
   */
  gender?: PharmacyCounselorMedicineNotebookAutoLinkPatientGenderEnum;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorMedicineNotebookAutoLinkPatient
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAutoLinkPatient
   */
  kanaFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAutoLinkPatient
   */
  kanaLastName?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAutoLinkPatient
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAutoLinkPatient
   */
  receiptCode?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAutoLinkPatient
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorMedicineNotebookAutoLinkPatientGenderEnum {
  Male = "male",
  Female = "female",
  None = "none",
}

export function PharmacyCounselorMedicineNotebookAutoLinkPatientFromJSON(
  json: any
): PharmacyCounselorMedicineNotebookAutoLinkPatient {
  return PharmacyCounselorMedicineNotebookAutoLinkPatientFromJSONTyped(json, false);
}

export function PharmacyCounselorMedicineNotebookAutoLinkPatientFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorMedicineNotebookAutoLinkPatient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorMedicineNotebookAutoLinkPatientTypename,

    dateOfBirth: !exists(json, "date_of_birth") ? undefined : json["date_of_birth"],
    externalId: !exists(json, "external_id") ? undefined : json["external_id"],
    externalIdV2: !exists(json, "external_id_v2")
      ? undefined
      : PharmacyCounselorExternalIDFromJSON(json["external_id_v2"]),
    familyAccountId: !exists(json, "family_account_id") ? undefined : json["family_account_id"],
    firstName: json["first_name"],
    gender: !exists(json, "gender") ? undefined : json["gender"],
    id: !exists(json, "id") ? undefined : json["id"],
    kanaFirstName: !exists(json, "kana_first_name") ? undefined : json["kana_first_name"],
    kanaLastName: !exists(json, "kana_last_name") ? undefined : json["kana_last_name"],
    lastName: json["last_name"],
    receiptCode: !exists(json, "receipt_code") ? undefined : json["receipt_code"],
  };
}

export function PharmacyCounselorMedicineNotebookAutoLinkPatientToJSON(
  object?: PharmacyCounselorMedicineNotebookAutoLinkPatient | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date_of_birth: value.dateOfBirth,
    external_id: value.externalId,
    external_id_v2: PharmacyCounselorExternalIDToJSON(value.externalIdV2),
    family_account_id: value.familyAccountId,
    first_name: value.firstName,
    gender: value.gender,
    id: value.id,
    kana_first_name: value.kanaFirstName,
    kana_last_name: value.kanaLastName,
    last_name: value.lastName,
    receipt_code: value.receiptCode,
  };
}
