import FullscreenDialog from "@mgdx/ui/components/FullscreenDialog";
import React, { FC } from "react";

import { AgreementNewTermsReasonView, AgreementNewTermsReasonViewProps } from "../../views/AgreementNewTermsReasonView";

export const AgreementNewTermsReasonDialog: FC<AgreementNewTermsReasonViewProps> = ({
  onClickAgreement,
  onClickShowWithdraw,
}) => {
  return (
    <FullscreenDialog isOpen={true} className="!bg-bg_all">
      <AgreementNewTermsReasonView onClickAgreement={onClickAgreement} onClickShowWithdraw={onClickShowWithdraw} />
    </FullscreenDialog>
  );
};
