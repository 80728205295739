import isClient from "@mgdx/shared/src/utils/isClient";
import { logger } from "@mgdx-libs/logger";

type AppBridgePublisher = { postMessage: (message: string) => void };
export type AppBridgeRequest<T> =
  | {
      status: "success";
      data: T;
    }
  | {
      status: "failure";
    };

declare global {
  interface Window {
    yqbGetAppVersion: AppBridgePublisher;
    yqbGetAppNetUserId: AppBridgePublisher;
    yqbOpenExternalBrowser: AppBridgePublisher;
    yqbShowBackButton: AppBridgePublisher;
    yqbHideBackButton: AppBridgePublisher;
    yqbRequestAppLogin: AppBridgePublisher;
    yqbGetNotificationPermissionStatus: AppBridgePublisher;
    yqbGetAppUserId: AppBridgePublisher;
    yqbOpenNotificationSetting: AppBridgePublisher;
    yqbShowNotificationRequestSheet: AppBridgePublisher;
    yqbGetCameraPermissionStatus: AppBridgePublisher;
    yqbGetPhotoPermissionStatus: AppBridgePublisher;
    yqbGetLocationPermissionStatus: AppBridgePublisher;
    yqbOpenAppSetting: AppBridgePublisher;
    yqbScheduleNotification: AppBridgePublisher;
    yqbGetScheduledNotificationConfig: AppBridgePublisher;
    yqbCloseWebView: AppBridgePublisher;
  }
}

// memo: 関数はアプリ側に実装されている
export const isCallableGetAppVersion = (): boolean => {
  return isClient() && !!window.yqbGetAppVersion;
};

export const callGetAppVersion = (): void => {
  if (isCallableGetAppVersion()) {
    window.yqbGetAppVersion.postMessage("");
    logger.debug("called yqbGetAppVersion.postMessage");
  }
};

export const callYqbGetAppNetUserId = (message: object): void => {
  if (window.yqbGetAppNetUserId) {
    window.yqbGetAppNetUserId.postMessage(JSON.stringify(message));
  }
  logger.debug("called yqbGetAppNetUserId.postMessage");
};

export const callYqbGetAppUserId = (message: object): void => {
  if (window.yqbGetAppUserId) {
    window.yqbGetAppUserId.postMessage(JSON.stringify(message));
  }
  logger.debug("called yqbGetAppUserId.postMessage");
};

export const callShowBackButtonInApp = (): void => {
  if (window.yqbShowBackButton) {
    window.yqbShowBackButton.postMessage("");
  }
  logger.debug("called yqbShowBackButton.postMessage");
};

export const callHideBackButtonInApp = (): void => {
  if (window.yqbHideBackButton) {
    window.yqbHideBackButton.postMessage("");
  }
  logger.debug("called yqbHideBackButton.postMessage");
};

export const callRequestAppLogin = (): void => {
  if (window.yqbRequestAppLogin) {
    window.yqbRequestAppLogin.postMessage("");
  }
  logger.debug("called yqbRequestAppLogin.postMessage");
};

export const callYqbExternalBrowser = (url: string): void => {
  if (window.yqbOpenExternalBrowser) {
    window.yqbOpenExternalBrowser.postMessage(url);
  }
  logger.debug("called yqbOpenExternalBrowser.postMessage, url: %s", url);
};

export const callGetNotificationPermissionStatus = (): void => {
  if (window.yqbGetNotificationPermissionStatus) {
    window.yqbGetNotificationPermissionStatus.postMessage("");
  }
  logger.debug("called yqbGetNotificationPermissionStatus.postMessage");
};

export const callGetAppUserId = (): void => {
  if (window.yqbGetAppUserId) {
    window.yqbGetAppUserId.postMessage("");
  }
  logger.debug("called yqbGetAppUserId.postMessage");
};

export const callOpenNotificationSetting = (): void => {
  if (window.yqbOpenNotificationSetting) {
    window.yqbOpenNotificationSetting.postMessage("");
  }
  logger.debug("called yqbOpenNotificationSetting.postMessage");
};

export const callShowNotificationRequestSheet = (): void => {
  if (window.yqbShowNotificationRequestSheet) {
    window.yqbShowNotificationRequestSheet.postMessage("");
  }
  logger.debug("called yqbShowNotificationRequestSheet.postMessage");
};

export const callGetCameraPermissionStatus = (): void => {
  if (window.yqbGetCameraPermissionStatus) {
    window.yqbGetCameraPermissionStatus.postMessage("");
  }
  logger.debug("called yqbGetCameraPermissionStatus.postMessage");
};

export const callGetPhotoPermissionStatus = (): void => {
  if (window.yqbGetPhotoPermissionStatus) {
    window.yqbGetPhotoPermissionStatus.postMessage("");
  }
  logger.debug("called yqbGetPhotoPermissionStatus.postMessage");
};

export const callGetLocationPermissionStatus = (): void => {
  if (window.yqbGetLocationPermissionStatus) {
    window.yqbGetLocationPermissionStatus.postMessage("");
  }
  logger.debug("called yqbGetLocationPermissionStatus.postMessage");
};

export const callOpenAppSetting = (): void => {
  if (window.yqbOpenAppSetting) {
    window.yqbOpenAppSetting.postMessage("");
  }
  logger.debug("called yqbOpenAppSetting.postMessage");
};

/**
 * # 正常系
 * {
 *   "status": "success",
 *   "data" : [
 *     {
 *       "id": "id-1",
 *       "message": "Push notification messages.",
 *       "local_notification_times": [
 *         "2024-07-24T09:10:00+09:00",
 *         "2024-07-24T12:20:00+09:00",
 *         "2024-07-24T15:30:00+09:00",
 *         "2024-07-24T18:40:00+09:00",
 *         "2024-07-24T21:50:00+09:00"
 *       ],
 *       "path": "/path/from/push/notifications"
 *     },
 *     {
 *       "id": "id-2",
 *       "message": "Push notification messages.",
 *       "local_notification_times": [
 *         "2024-07-28T09:00:00+09:00"
 *       ],
 *       "path": ""/path/from/push/notifications""
 *     }
 *   ]
 * }
 *
 * # 異常系
 * {
 *   "status": "failure"
 * }"
 */
type ScheduleNotificationData = {
  id: string;
  message: string;
  localNotificationTimes: string[];
  path: string;
};
export const convertToScheduleNotificationRequestData = (
  schedules: ScheduleNotificationData[]
): AppBridgeRequest<ScheduleNotificationData[]> => {
  return {
    status: "success",
    data: schedules.map((schedule) => ({
      id: schedule.id,
      message: schedule.message,
      localNotificationTimes: schedule.localNotificationTimes,
      path: schedule.path,
    })),
  };
};
export const callAddScheduleNotification = (data: AppBridgeRequest<ScheduleNotificationData[]>): void => {
  try {
    const message = JSON.stringify(data);
    if (window.yqbScheduleNotification) {
      window.yqbScheduleNotification.postMessage(message);
    }
    logger.debug("called yqbScheduleNotification.postMessage, message: %s", message);
  } catch (e) {
    logger.error("Failed to send schedule notification data: %s", e.message);
  }
};

export const callGetScheduledNotificationConfig = (): void => {
  if (window.yqbGetScheduledNotificationConfig) {
    window.yqbGetScheduledNotificationConfig.postMessage("");
  }
  logger.debug("called yqbGetScheduledNotificationConfig.postMessage");
};

export const callCloseWebView = (): void => {
  if (window.yqbCloseWebView) {
    window.yqbCloseWebView.postMessage("");
  }
  logger.debug("called yqbCloseWebView.postMessage");
};
