import clsx from "clsx";
import React from "react";

import * as styles from "./SvgIcon.module.css";

export const sizes = ["xs", "s", "m", "xm", "xxm", "l"] as const;

export const variants = ["primary", "secondary", "tertiary", "text_link", "key"] as const;

export type Props = React.SVGProps<SVGSVGElement> & {
  size?: (typeof sizes)[number];
  variant?: (typeof variants)[number];
};

const ArrowBackBriefIcon: React.FC<Props> = ({ className, size = "m", variant, ...props }) => {
  const fill = props.fill ?? "inherit";
  return (
    <svg
      fill={fill}
      role={props["aria-label"] ? "img" : undefined}
      aria-hidden={props["aria-hidden"] ?? !props["aria-label"] ? true : undefined}
      className={clsx(
        size === "xs" && styles.iconSizeXS,
        size === "s" && styles.iconSizeS,
        size === "m" && styles.iconSizeM,
        size === "xm" && styles.iconSizeXM,
        size === "xxm" && styles.iconSizeXXM,
        size === "l" && styles.iconSizeL,
        variant === "primary" && styles.iconPrimary,
        variant === "secondary" && styles.iconSecondary,
        variant === "tertiary" && styles.iconTertiary,
        variant === "text_link" && styles.iconLink,
        variant === "key" && styles.iconKey,
        className
      )}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7996 4.98966C16.0814 5.2932 16.0639 5.76775 15.7603 6.0496L9.35221 12L15.7603 17.9504C16.0639 18.2323 16.0814 18.7068 15.7996 19.0103C15.5177 19.3139 15.0432 19.3314 14.7397 19.0496L7.73966 12.5496C7.58683 12.4077 7.5 12.2086 7.5 12C7.5 11.7915 7.58683 11.5923 7.73966 11.4504L14.7397 4.95041C15.0432 4.66856 15.5177 4.68613 15.7996 4.98966Z"
      />
    </svg>
  );
};

export default ArrowBackBriefIcon;
