import clsx from "clsx";
import React from "react";

import * as styles from "./PlainLink.module.css";

export type PlainLinkExternalProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "title"> & {
  title: string;
  widthType?: "over-container" | "full-width" | "fit-content";
  align?: "center" | "left" | "right";
  noPadding?: boolean;
  iconPosition?: "top" | "center";
};

export const PlainLinkExternal: React.FC<PlainLinkExternalProps> = ({
  title,
  target = "_blank",
  rel = "noopener noreferrer",
  widthType,
  align = "center",
  noPadding,
  iconPosition = "center",
  className,
  children,
  ...props
}) => {
  return (
    <a
      title={`${title}(別ウィンドウで開きます)`}
      target={target}
      rel={rel}
      className={clsx(
        styles.root,
        widthType === "over-container" && styles.overContainer,
        widthType === "full-width" && styles.fullWidth,
        noPadding && styles.noPadding,
        align === "center" && styles.textCenter,
        align === "left" && styles.textLeft,
        align === "right" && styles.textRight,
        iconPosition === "top" && styles.iconTop,
        iconPosition === "center" && styles.iconCenter,
        className
      )}
      {...props}
    >
      {children}
    </a>
  );
};
