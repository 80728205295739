// extracted by mini-css-extract-plugin
export var accent_primary = "Chip-module--accent_primary--a5819";
export var accent_secondary = "Chip-module--accent_secondary--da7b8";
export var accent_tertiary = "Chip-module--accent_tertiary--cb375";
export var caution = "Chip-module--caution--4a5f1";
export var chip = "Chip-module--chip--378e4";
export var notice = "Chip-module--notice--5fb28";
export var nowrap = "Chip-module--nowrap--f3340";
export var sizeS = "Chip-module--sizeS--b5eff";
export var text = "Chip-module--text--20361";
export var textBold = "Chip-module--textBold--01174";
export var typeWeakest = "Chip-module--typeWeakest--c6d90";
export var weak = "Chip-module--weak--6bb42";