import { useLocation } from "@mgdx-libs/router";
import React from "react";

import { useGetServiceTermsQuery } from "../../hooks/patientServiceTerm/useGetServiceTermsQuery";
import useCurrentUser from "../../hooks/useCurrentUser";
import { useIsRequiredAgreementNewTerms } from "../../hooks/useIsRequiredAgreementNewTerms";
import { AgreementNewTermsMain } from "./AgreementNewTermsMain";

export const AgreementNewTermsApp = () => {
  const { pathname: currentPathname } = useLocation();
  const currentUser = useCurrentUser();
  const { data: currentServiceTerm } = useGetServiceTermsQuery({
    enabled: currentUser.isLoggedInAndValidAccessToken,
  });

  const isRequiredAgreementNewTerms = useIsRequiredAgreementNewTerms({
    currentPathname,
    currentServiceTerm,
    currentUser,
  });

  if (isRequiredAgreementNewTerms) {
    return <AgreementNewTermsMain />;
  }

  return null;
};
