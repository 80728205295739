/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientMedicineAlarmSettingStatus,
  PatientMedicineAlarmSettingStatusFromJSON,
  PatientMedicineAlarmSettingStatusFromJSONTyped,
  PatientMedicineAlarmSettingStatusToJSON,
  PatientMedicineAlarmTiming,
  PatientMedicineAlarmTimingFromJSON,
  PatientMedicineAlarmTimingFromJSONTyped,
  PatientMedicineAlarmTimingToJSON,
} from "./";

export const PatientMedicineAlarmSettingTypename = "PatientMedicineAlarmSetting" as const;

/**
 *
 * @export
 * @interface PatientMedicineAlarmSetting
 */
export interface PatientMedicineAlarmSetting {
  /**
   *
   * @type {number}
   * @memberof PatientMedicineAlarmSetting
   */
  chainId: number;
  /**
   *
   * @type {number}
   * @memberof PatientMedicineAlarmSetting
   */
  dispenseRecordId?: number;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineAlarmSetting
   */
  endDate?: string;
  /**
   *
   * @type {number}
   * @memberof PatientMedicineAlarmSetting
   */
  familyAccountId?: number;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineAlarmSetting
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof PatientMedicineAlarmSetting
   */
  otcMedicineId?: number;
  /**
   *
   * @type {number}
   * @memberof PatientMedicineAlarmSetting
   */
  patientId: number;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineAlarmSetting
   */
  requestType: PatientMedicineAlarmSettingRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineAlarmSetting
   */
  startDate: string;
  /**
   *
   * @type {PatientMedicineAlarmSettingStatus}
   * @memberof PatientMedicineAlarmSetting
   */
  status?: PatientMedicineAlarmSettingStatus;
  /**
   *
   * @type {Array<PatientMedicineAlarmTiming>}
   * @memberof PatientMedicineAlarmSetting
   */
  timingsOfTheDay: Array<PatientMedicineAlarmTiming>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMedicineAlarmSetting
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientMedicineAlarmSettingRequestTypeEnum {
  Web = "web",
  Line = "line",
}

export function PatientMedicineAlarmSettingFromJSON(json: any): PatientMedicineAlarmSetting {
  return PatientMedicineAlarmSettingFromJSONTyped(json, false);
}

export function PatientMedicineAlarmSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientMedicineAlarmSetting {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMedicineAlarmSettingTypename,

    chainId: json["chain_id"],
    dispenseRecordId: !exists(json, "dispense_record_id") ? undefined : json["dispense_record_id"],
    endDate: !exists(json, "end_date") ? undefined : json["end_date"],
    familyAccountId: !exists(json, "family_account_id") ? undefined : json["family_account_id"],
    id: json["id"],
    otcMedicineId: !exists(json, "otc_medicine_id") ? undefined : json["otc_medicine_id"],
    patientId: json["patient_id"],
    requestType: json["request_type"],
    startDate: json["start_date"],
    status: !exists(json, "status") ? undefined : PatientMedicineAlarmSettingStatusFromJSON(json["status"]),
    timingsOfTheDay: (json["timings_of_the_day"] as Array<any>).map(PatientMedicineAlarmTimingFromJSON),
  };
}

export function PatientMedicineAlarmSettingToJSON(object?: PatientMedicineAlarmSetting | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    chain_id: value.chainId,
    dispense_record_id: value.dispenseRecordId,
    end_date: value.endDate,
    family_account_id: value.familyAccountId,
    id: value.id,
    otc_medicine_id: value.otcMedicineId,
    patient_id: value.patientId,
    request_type: value.requestType,
    start_date: value.startDate,
    status: PatientMedicineAlarmSettingStatusToJSON(value.status),
    timings_of_the_day: (value.timingsOfTheDay as Array<any>).map(PatientMedicineAlarmTimingToJSON),
  };
}
