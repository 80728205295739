import DropdownIcon from "@mgdx/assets/icons/DropdownIcon";
import clsx from "clsx";
import React from "react";

import { PlainLinkButton } from "../../components/PlainLink";
import { InputRadio, InputRadioProps } from "../InputRadio";
import * as styles from "./ToggleInputGroup.module.css";
import { ToggleInputGroupBase, ToggleInputGroupBaseProps } from "./ToggleInputGroupBase";

export type RadioGroupOptionProps = Omit<InputRadioProps, "name" | "value" | "onChange"> & {
  label: React.ReactNode;
  value: string;
};

export type ToggleInputGroupRadioProps = Pick<InputRadioProps, "name" | "value" | "onChange"> &
  Pick<
    ToggleInputGroupBaseProps,
    "label" | "required" | "disabled" | "completed" | "error" | "message" | "noMessageArea" | "className"
  > & {
    radioGroupOptions: RadioGroupOptionProps[];
    limit?: number;
  };

export const ToggleInputGroupRadio: React.FC<ToggleInputGroupRadioProps> = ({
  /* ToggleInputGroupBaseProps */
  label,
  required,
  disabled,
  completed,
  error,
  message,
  noMessageArea,
  className,
  /* InputRadioProps */
  name,
  value,
  onChange,
  /* my props */
  radioGroupOptions,
  limit,
}) => {
  const [isOptionsCollapsed, setIsOptionsCollapsed] = React.useState(limit && radioGroupOptions.length > limit);
  const visibleOptions = isOptionsCollapsed ? radioGroupOptions.slice(0, limit) : radioGroupOptions;

  return (
    <ToggleInputGroupBase
      label={label}
      error={error}
      message={message}
      required={required}
      disabled={disabled}
      noMessageArea={noMessageArea}
      className={className}
    >
      {visibleOptions.map(
        ({ label, value: optionValue, disabled: optionDisabled, completed: optionCompleted, ...radioProps }, index) => (
          <InputRadio
            key={`item${index}`}
            {...radioProps}
            onChange={onChange}
            name={name}
            value={optionValue}
            checked={optionValue === value}
            completed={optionCompleted ?? completed}
            disabled={disabled || optionDisabled}
            widePadding={true}
            nowrap={false}
            className={clsx(
              styles.radio,
              index !== 0 && styles.divider,
              (disabled || optionDisabled) && styles.disabled
            )}
          >
            {label}
          </InputRadio>
        )
      )}
      {isOptionsCollapsed && (
        <PlainLinkButton
          onClick={() => setIsOptionsCollapsed(false)}
          className={clsx(styles.item, styles.divider, styles.checkbox)}
        >
          もっと見る
          <DropdownIcon variant="secondary" />
        </PlainLinkButton>
      )}
    </ToggleInputGroupBase>
  );
};
