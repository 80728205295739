import clsx from "clsx";
import React from "react";

import * as styles from "./Chip.module.css";

export const variants = ["accent_primary", "accent_secondary", "accent_tertiary", "notice", "caution", "weak"] as const;
export type Variant = (typeof variants)[number];
export const sizes = ["s", "xm"] as const;

export type Props = React.HTMLAttributes<HTMLDivElement> &
  React.PropsWithChildren<{
    variant: Variant;
    size?: (typeof sizes)[number];
    isWeakest?: boolean;
    isBold?: boolean;
    nowrap?: boolean;
  }>;

const Chip = ({ variant, size = "xm", isWeakest, isBold, nowrap, className, children, ...props }: Props) => (
  <div
    {...props}
    className={clsx(
      styles.chip,
      variant === "accent_primary" && styles.accent_primary,
      variant === "accent_secondary" && styles.accent_secondary,
      variant === "accent_tertiary" && styles.accent_tertiary,
      variant === "notice" && styles.notice,
      variant === "caution" && styles.caution,
      variant === "weak" && styles.weak,
      isWeakest && styles.typeWeakest,
      size === "s" && styles.sizeS,
      nowrap && styles.nowrap,
      className
    )}
  >
    <div className={clsx(styles.text, isBold && styles.textBold)}>{children}</div>
  </div>
);

export default Chip;
