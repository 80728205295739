/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  GetInterviewResponse,
  GetInterviewResponseFromJSON,
  GetInterviewResponseToJSON,
  PharmacyCounselorInterview,
  PharmacyCounselorInterviewFromJSON,
  PharmacyCounselorInterviewToJSON,
} from "../models";

export interface GetInterviewRequest {
  counselingId: number;
}

export interface PutInterviewRequest {
  counselingId: number;
  pharmacyCounselorInterview: PharmacyCounselorInterview;
}

/**
 *
 */
export class InterviewApi extends runtime.BaseAPI {
  /**
   * Get counseling interview using JWT.
   */
  async getInterviewRaw(
    requestParameters: GetInterviewRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetInterviewResponse>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling getInterview."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/interview`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewResponseFromJSON(jsonValue));
  }

  /**
   * Get counseling interview using JWT.
   */
  async getInterview(
    requestParameters: GetInterviewRequest,
    initOverrides?: RequestInit
  ): Promise<GetInterviewResponse> {
    return promiseRetry(
      (retry) =>
        this.getInterviewRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Answer to interview
   */
  async putInterviewRaw(
    requestParameters: PutInterviewRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyCounselorInterview>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling putInterview."
      );
    }

    if (
      requestParameters.pharmacyCounselorInterview === null ||
      requestParameters.pharmacyCounselorInterview === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyCounselorInterview",
        "Required parameter requestParameters.pharmacyCounselorInterview was null or undefined when calling putInterview."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/interview`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyCounselorInterviewToJSON(requestParameters.pharmacyCounselorInterview),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyCounselorInterviewFromJSON(jsonValue));
  }

  /**
   * Answer to interview
   */
  async putInterview(
    requestParameters: PutInterviewRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyCounselorInterview> {
    return promiseRetry(
      (retry) =>
        this.putInterviewRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
