import OpenInNewIcon from "@mgdx/assets/icons/OpenInNewIcon";
import clsx from "clsx";
import React, { AnchorHTMLAttributes } from "react";

import * as styles from "./TextInnerLink.module.css";

export type TextInnerLinkExternalProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "children"> & {
  children: string;
  disabled?: boolean;
  textSize?: "xm" | "xxs";
};

export const TextInnerLinkExternal: React.FC<TextInnerLinkExternalProps> = ({
  children,
  className,
  disabled,
  href,
  rel = "noopener noreferrer",
  target = "_blank",
  textSize = "xm",
  title,
  ...props
}) => (
  <a
    href={href}
    title={`${title || children}(別ウィンドウで開きます)`}
    target={target}
    rel={rel}
    className={clsx(styles.container, textSize === "xxs" && styles.textSizeXxs, disabled && styles.disabled, className)}
    {...props}
  >
    <span>
      {children}
      <OpenInNewIcon size="s" variant="text_link" className={clsx(styles.icon, textSize !== "xxs" && "ml-1")} />
    </span>
  </a>
);
