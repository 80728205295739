/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorExaminer,
  PharmacyCounselorExaminerFromJSON,
  PharmacyCounselorExaminerFromJSONTyped,
  PharmacyCounselorExaminerToJSON,
  PharmacyCounselorExternalID,
  PharmacyCounselorExternalIDFromJSON,
  PharmacyCounselorExternalIDFromJSONTyped,
  PharmacyCounselorExternalIDToJSON,
  PharmacyCounselorFamilyPharmacistQuestionnaire,
  PharmacyCounselorFamilyPharmacistQuestionnaireFromJSON,
  PharmacyCounselorFamilyPharmacistQuestionnaireFromJSONTyped,
  PharmacyCounselorFamilyPharmacistQuestionnaireToJSON,
} from "./";

export const PharmacyCounselorCounselingPatientTypename = "PharmacyCounselorCounselingPatient" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorCounselingPatient
 */
export interface PharmacyCounselorCounselingPatient {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingPatient
   */
  addressLine1?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingPatient
   */
  addressLine2?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingPatient
   */
  email?: string;
  /**
   *
   * @type {PharmacyCounselorExaminer}
   * @memberof PharmacyCounselorCounselingPatient
   */
  examiner?: PharmacyCounselorExaminer;
  /**
   *
   * @type {PharmacyCounselorExternalID}
   * @memberof PharmacyCounselorCounselingPatient
   */
  externalIdV2?: PharmacyCounselorExternalID;
  /**
   *
   * @type {PharmacyCounselorFamilyPharmacistQuestionnaire}
   * @memberof PharmacyCounselorCounselingPatient
   */
  familyPharmacistQuestionnaire?: PharmacyCounselorFamilyPharmacistQuestionnaire;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingPatient
   */
  firstName?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorCounselingPatient
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingPatient
   */
  kanaFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingPatient
   */
  kanaLastName?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingPatient
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingPatient
   */
  postalCode?: string;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyCounselorCounselingPatient
   */
  smsAvailable?: boolean;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingPatient
   */
  status?: PharmacyCounselorCounselingPatientStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingPatient
   */
  tel?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorCounselingPatient
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorCounselingPatientStatusEnum {
  Registered = "registered",
  Verified = "verified",
  Withdrew = "withdrew",
}

export function PharmacyCounselorCounselingPatientFromJSON(json: any): PharmacyCounselorCounselingPatient {
  return PharmacyCounselorCounselingPatientFromJSONTyped(json, false);
}

export function PharmacyCounselorCounselingPatientFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorCounselingPatient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorCounselingPatientTypename,

    addressLine1: !exists(json, "address_line1") ? undefined : json["address_line1"],
    addressLine2: !exists(json, "address_line2") ? undefined : json["address_line2"],
    email: !exists(json, "email") ? undefined : json["email"],
    examiner: !exists(json, "examiner") ? undefined : PharmacyCounselorExaminerFromJSON(json["examiner"]),
    externalIdV2: !exists(json, "external_id_v2")
      ? undefined
      : PharmacyCounselorExternalIDFromJSON(json["external_id_v2"]),
    familyPharmacistQuestionnaire: !exists(json, "family_pharmacist_questionnaire")
      ? undefined
      : PharmacyCounselorFamilyPharmacistQuestionnaireFromJSON(json["family_pharmacist_questionnaire"]),
    firstName: !exists(json, "first_name") ? undefined : json["first_name"],
    id: !exists(json, "id") ? undefined : json["id"],
    kanaFirstName: !exists(json, "kana_first_name") ? undefined : json["kana_first_name"],
    kanaLastName: !exists(json, "kana_last_name") ? undefined : json["kana_last_name"],
    lastName: !exists(json, "last_name") ? undefined : json["last_name"],
    postalCode: !exists(json, "postal_code") ? undefined : json["postal_code"],
    smsAvailable: !exists(json, "sms_available") ? undefined : json["sms_available"],
    status: !exists(json, "status") ? undefined : json["status"],
    tel: !exists(json, "tel") ? undefined : json["tel"],
  };
}

export function PharmacyCounselorCounselingPatientToJSON(object?: PharmacyCounselorCounselingPatient | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    address_line1: value.addressLine1,
    address_line2: value.addressLine2,
    email: value.email,
    examiner: PharmacyCounselorExaminerToJSON(value.examiner),
    external_id_v2: PharmacyCounselorExternalIDToJSON(value.externalIdV2),
    family_pharmacist_questionnaire: PharmacyCounselorFamilyPharmacistQuestionnaireToJSON(
      value.familyPharmacistQuestionnaire
    ),
    first_name: value.firstName,
    id: value.id,
    kana_first_name: value.kanaFirstName,
    kana_last_name: value.kanaLastName,
    last_name: value.lastName,
    postal_code: value.postalCode,
    sms_available: value.smsAvailable,
    status: value.status,
    tel: value.tel,
  };
}
