import clsx from "clsx";
import React from "react";

import Message from "../../Message";
import * as styles from "./BaseField.module.css";

/**
 * @deprecated
 */
export type Props = React.PropsWithChildren<{
  className?: string;
  errorMessage?: string;
  message?: string;
  noMessage?: boolean;
  messageClassName?: string;
}>;

/**
 * @deprecated use InputFieldMessage from ui/components/InputFieldMessage instead
 */
const BaseField = ({ className, errorMessage, message, noMessage, children, messageClassName, ...props }: Props) => {
  return (
    <div className={clsx(styles.field, noMessage && styles.noMessage, className)}>
      {children}
      <div className={clsx(styles.messages, messageClassName)}>
        {errorMessage ? (
          <Message variant="caution">{errorMessage}</Message>
        ) : message ? (
          <Message variant="weak">{message}</Message>
        ) : (
          <Message variant="weak">&nbsp;</Message>
        )}
      </div>
    </div>
  );
};

export default BaseField;
