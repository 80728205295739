import clsx from "clsx";
import React, { PropsWithChildren } from "react";
import Div100vh from "react-div-100vh";

import { PreloaderForMccm } from "./PreloaderForMccm";
import * as styles from "./PreloaderForMccm.module.css";

export type FullscreenCoverPreloaderProps = PropsWithChildren & {};

export const FullscreenCoverPreloader: React.FC<FullscreenCoverPreloaderProps> = ({ children }) => {
  return (
    <Div100vh as="main" style={{ height: "100rvh", width: "100vw", overflow: "hidden" }}>
      {children}
      <PreloaderForMccm className={clsx(styles.cover, "fixed left-0 top-0")} />
    </Div100vh>
  );
};
