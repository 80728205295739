import patientApi from "@mgdx/api/lib/patientApi";
import { PatientUpdateStatusEnum } from "@mgdx/shared/src/models/Patient";
import { apiErrorHandler, errorHandlerReport } from "@mgdx-libs/error-handler";
import { Dispatch } from "redux";

import { setCurrentUserWithDispatch } from "../../ducks/currentUser";

export const updatePatientToEmailVerifiedAndSetToStore = async (dispatch: Dispatch) => {
  await patientApi
    .putPatientStatus({
      putPatientStatusRequestBody: {
        status: PatientUpdateStatusEnum.Verified,
      },
    })
    .then((patient) => {
      setCurrentUserWithDispatch(dispatch, patient);
    })
    .catch(async (errorOrResponse) => {
      if (errorOrResponse instanceof Response) {
        const apiError = await apiErrorHandler(errorOrResponse);
        errorHandlerReport(apiError);
        throw apiError;
      } else {
        await errorHandlerReport(errorOrResponse);
        throw errorOrResponse;
      }
    });
};
