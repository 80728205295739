import { PatientServiceTerm } from "@mgdx/api/lib/patient/patient";
import parseDate from "@mgdx/shared/src/utils/parseDate";
import { useMemo } from "react";

import { CurrentUserState } from "../ducks/currentUser";

type UseIsRequiredAgreementNewTermsArgs = {
  currentPathname: string;
  currentServiceTerm: PatientServiceTerm | undefined;
  currentUser: CurrentUserState;
};

export const useIsRequiredAgreementNewTerms = ({
  currentPathname,
  currentServiceTerm,
  currentUser,
}: UseIsRequiredAgreementNewTermsArgs) =>
  useMemo<boolean>(() => {
    if (currentPathname.startsWith("/rules")) {
      return false;
    }

    if (!currentUser.isLoggedIn) {
      return false;
    }

    if (!currentUser.userInfo) {
      return false;
    }

    const yqbAgreedTime = parseDate(currentUser.userInfo.yqbAgreedTime);

    if (!yqbAgreedTime) {
      // 同意日付がない場合は同意が必要
      return true;
    }

    const newTermsLatestRevisionDate = parseDate(currentServiceTerm?.revision);

    if (!newTermsLatestRevisionDate) {
      // 改定日付を取得中の場合は不要
      return false;
    }

    return newTermsLatestRevisionDate.isSameOrAfter(yqbAgreedTime);
  }, [currentPathname, currentServiceTerm, currentUser.isLoggedIn, currentUser.userInfo]);
