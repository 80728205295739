import { logger } from "@mgdx-libs/logger";
import { AuthError } from "firebase/auth";

export const defaultErrorMessage = "しばらく経ってから再度お試しください";

const translations = {
  createUser: {
    "auth/email-already-in-use": "メールアドレスは既に使われています。",
    "auth/invalid-email": "メールアドレスが不正な形式です。",
    "auth/weak-password": "パスワードの強度が十分ではありません。",
  },
  verifyEmail: {
    "auth/expired-action-code": "このメール上のリンクは有効期限が切れています。",
    "auth/invalid-action-code": "このメール上のリンクは既に認証済みです。",
    "auth/user-disabled": "このメール上のリンクは無効なリンクです。",
    "auth/user-not-found": "このメール上のリンクは無効なリンクです。",
  },
  sendEmailVerification: {},
  signOut: {},

  /**
   * @see https://firebase.google.com/docs/reference/js/firebase.auth.Auth#verifypasswordresetcode
   */
  verifyPasswordResetCode: {
    "auth/expired-action-code": "このリンクは有効期限が切れています。",
    "auth/invalid-action-code": "このリンクは無効なリンクです。",
  },

  /**
   * @see https://firebase.google.com/docs/reference/js/firebase.auth.Auth#confirmpasswordreset
   */
  confirmPasswordReset: {
    "auth/expired-action-code": "このリンクは有効期限が切れています。",
    "auth/invalid-action-code": "このリンクは無効なリンクです。",
    "auth/weak-password": "パスワードの強度が十分ではありません。",
  },

  /**
   * @see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithemailandpassword
   */
  signInWithEmailAndPassword: {
    "auth/invalid-email": "メールアドレスが不正な形式です。",
    "auth/user-disabled": "無効なメールアドレスです。",
    "auth/user-not-found": "メールアドレスまたはパスワードが正しくありません。",
    "auth/wrong-password": "メールアドレスまたはパスワードが正しくありません。",
  },

  /**
   * @see https://firebase.google.com/docs/reference/js/firebase.User#reauthenticatewithcredential
   */
  reauthenticateWithCredential: {
    "auth/wrong-password": "パスワードが間違っています。",
  },

  /**
   * @see https://firebase.google.com/docs/reference/js/firebase.User#updateemail
   */
  updateEmail: {
    "auth/email-already-in-use": "メールアドレスは既に使われています。",
    "auth/invalid-email": "メールアドレスが不正な形式です。",
  },
  /*
   * @see  https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithpopup
   */
  signInWithPopup: {
    "auth/account-exists-with-different-credential": "異なるアカウントで既に認証済みです。",
    "auth/auth-domain-config-required": "ドメインが許可されていません。",
    "auth/cancelled-popup-request": "ポップアップがキャンセルされました",
    "auth/operation-not-allowed": "認証が無効です。",
    "auth/operation-not-supported-in-this-environment": "この操作は許可されていません。",
    "auth/popup-blocked": "ポップアップがブロックされました",
    "auth/popup-closed-by-user": "作業途中でポップアップが閉じられました",
    "auth/unauthorized-domain": "このサイトでは許可されていません。",
  },
  /*
   * @see  https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithcredential
   */
  signInWithCredential: {
    "auth/account-exists-with-different-credential": "異なるアカウントで既に認証済みです。",
    "auth/auth-domain-config-required": "ドメインが許可されていません。",
    "auth/email-already-in-use": "メールアドレスは既に使われています。",
    "auth/operation-not-allowed": "認証が無効です。",
    "auth/operation-not-supported-in-this-environment": "この操作は許可されていません。",
    "auth/timeout": "処理がタイムアウトしました",
  },
  /*
   * @see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#getredirectresult
   */
  getRedirectResult: {
    "auth/account-exists-with-different-credential": "異なるアカウントで既に認証済みです。",
    "auth/auth-domain-config-required": "ドメインが許可されていません。",
    "auth/credential-already-in-use": "認証が使われています。",
    "auth/email-already-in-use": "メールアドレスは既に使われています。",
    "auth/operation-not-allowed": "認証が無効です。",
    "auth/operation-not-supported-in-this-environment": "この操作は許可されていません。",
    "auth/timeout": "処理がタイムアウトしました",
    "auth/invalid-credential": "認証が無効です。",
  },
  /*
   * @see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithcustomtoken
   */
  signInWithCustomToken: {
    "auth/custom-token-mismatch": "トークンが無効です。",
    "auth/invalid-custom-token": "トークンが無効です。",
  },
  unlink: {
    "auth/no-such-provider": "連携されたサービスがありません。",
  },
};

export type MethodName = keyof typeof translations;

const firebaseErrorMessages = (methodName: MethodName, error: AuthError | any): string | undefined => {
  if ("code" in error) {
    logger.debug("firebaseErrorMessages: %o", { methodName, code: error.code });

    if (error.code === "auth/too-many-requests") {
      return defaultErrorMessage;
    }

    if (translations[methodName] && translations[methodName][error.code]) {
      return translations[methodName][error.code];
    }
  }

  return undefined;
};

export default firebaseErrorMessages;
