/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientMCCMJAHISSyncJob,
  PatientMCCMJAHISSyncJobFromJSON,
  PatientMCCMJAHISSyncJobFromJSONTyped,
  PatientMCCMJAHISSyncJobToJSON,
} from "./";

export const GetMCCMJAHISSyncJobsResponseTypename = "GetMCCMJAHISSyncJobsResponse" as const;

/**
 *
 * @export
 * @interface GetMCCMJAHISSyncJobsResponse
 */
export interface GetMCCMJAHISSyncJobsResponse {
  /**
   *
   * @type {number}
   * @memberof GetMCCMJAHISSyncJobsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<PatientMCCMJAHISSyncJob>}
   * @memberof GetMCCMJAHISSyncJobsResponse
   */
  mccmJahisSyncJobs?: Array<PatientMCCMJAHISSyncJob>;
  /**
   * Resource type name
   * @type {string}
   * @memberof GetMCCMJAHISSyncJobsResponse
   */
  readonly __typename?: string;
}

export function GetMCCMJAHISSyncJobsResponseFromJSON(json: any): GetMCCMJAHISSyncJobsResponse {
  return GetMCCMJAHISSyncJobsResponseFromJSONTyped(json, false);
}

export function GetMCCMJAHISSyncJobsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetMCCMJAHISSyncJobsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: GetMCCMJAHISSyncJobsResponseTypename,

    count: !exists(json, "count") ? undefined : json["count"],
    mccmJahisSyncJobs: !exists(json, "mccm_jahis_sync_jobs")
      ? undefined
      : (json["mccm_jahis_sync_jobs"] as Array<any>).map(PatientMCCMJAHISSyncJobFromJSON),
  };
}

export function GetMCCMJAHISSyncJobsResponseToJSON(object?: GetMCCMJAHISSyncJobsResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    count: value.count,
    mccm_jahis_sync_jobs:
      value.mccmJahisSyncJobs === undefined
        ? undefined
        : (value.mccmJahisSyncJobs as Array<any>).map(PatientMCCMJAHISSyncJobToJSON),
  };
}
