/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorExternalIDTypename = "PharmacyCounselorExternalID" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorExternalID
 */
export interface PharmacyCounselorExternalID {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorExternalID
   */
  digitalMemberId?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorExternalID
   */
  qolMemberId?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorExternalID
   */
  readonly __typename?: string;
}

export function PharmacyCounselorExternalIDFromJSON(json: any): PharmacyCounselorExternalID {
  return PharmacyCounselorExternalIDFromJSONTyped(json, false);
}

export function PharmacyCounselorExternalIDFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorExternalID {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorExternalIDTypename,

    digitalMemberId: !exists(json, "digital_member_id") ? undefined : json["digital_member_id"],
    qolMemberId: !exists(json, "qol_member_id") ? undefined : json["qol_member_id"],
  };
}

export function PharmacyCounselorExternalIDToJSON(object?: PharmacyCounselorExternalID | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    digital_member_id: value.digitalMemberId,
    qol_member_id: value.qolMemberId,
  };
}
