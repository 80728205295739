import { errorHandlerReport, isFirebaseError } from "@mgdx-libs/error-handler";
import { FirebaseAuthError } from "@mgdx-libs/error-handler/errors/fireErrors";
import { logger } from "@mgdx-libs/logger";
import { signInWithCustomToken } from "firebase/auth";

import { setAccessToken } from "./accessToken";
import { getFirebaseAuth } from "./getFirebaseAuth";

export const signInFirebaseWithCustomToken = async (customToken: string) => {
  const firebaseAuth = getFirebaseAuth();
  const userCredential = await signInWithCustomToken(firebaseAuth, customToken).catch((error) => {
    if (isFirebaseError(error)) {
      const firebaseAuthError = new FirebaseAuthError(error);
      errorHandlerReport(firebaseAuthError);
      throw firebaseAuthError;
    } else {
      errorHandlerReport(error);
      throw error;
    }
  });

  const firebaseUser = userCredential.user;

  logger.debug("firebase.User: %o", firebaseUser);
  const accessToken = await firebaseUser.getIdToken().catch((error) => {
    if (isFirebaseError(error)) {
      const firebaseAuthError = new FirebaseAuthError(error);
      errorHandlerReport(firebaseAuthError);
      throw firebaseAuthError;
    } else {
      errorHandlerReport(error);
      throw error;
    }
  });

  logger.debug("getIdToken: %s", accessToken);
  setAccessToken(accessToken);

  return firebaseUser;
};
