/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX bff-patient API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";

export interface DeleteFamilyAccountRequest {
  familyAccountId: number;
}

/**
 *
 */
export class FamilyAccountApi extends runtime.BaseAPI {
  /**
   * Delete family account
   */
  async deleteFamilyAccountRaw(
    requestParameters: DeleteFamilyAccountRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.familyAccountId === null || requestParameters.familyAccountId === undefined) {
      throw new runtime.RequiredError(
        "familyAccountId",
        "Required parameter requestParameters.familyAccountId was null or undefined when calling deleteFamilyAccount."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/family-accounts/{family_account_id}`.replace(
          `{${"family_account_id"}}`,
          encodeURIComponent(String(requestParameters.familyAccountId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete family account
   */
  async deleteFamilyAccount(requestParameters: DeleteFamilyAccountRequest, initOverrides?: RequestInit): Promise<void> {
    await this.deleteFamilyAccountRaw(requestParameters, initOverrides);
  }
}
