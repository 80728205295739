import CheckIcon from "@mgdx/assets/icons/CheckIcon";
import clsx from "clsx";
import React from "react";

import * as styles from "./InputCheckbox.module.css";

export type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  completed?: boolean;
};

export const Checkbox = ({ className, disabled, completed, checked, ...props }: CheckboxProps) => {
  return (
    <div className={clsx(styles.checkbox, completed && styles.completed, disabled && styles.disabled)}>
      <input {...props} disabled={disabled || completed} type="checkbox" className={styles.input} checked={checked} />
      <div className={clsx(styles.indicator, completed && styles.completed, disabled && styles.disabled)}>
        <CheckIcon size="s" aria-hidden={true} className={styles.checkmark} />
      </div>
    </div>
  );
};
