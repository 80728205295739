import CheckIcon from "@mgdx/assets/icons/CheckIcon";
import clsx from "clsx";
import React from "react";

import * as styles from "./InputRadio.module.css";

export type RadioProps = React.InputHTMLAttributes<HTMLInputElement> & {
  completed?: boolean;
  disableActiveStyle?: boolean;
};

export const Radio: React.FC<RadioProps> = ({ disabled, completed, disableActiveStyle, className, ...props }) => (
  <div
    className={clsx(
      styles.radio,
      completed && styles.completed,
      disabled && styles.disabled,
      disableActiveStyle && styles.disableActive
    )}
  >
    <input type="radio" disabled={disabled} className={styles.input} {...props} />
    <div className={clsx(styles.indicator, completed && styles.completed, disabled && styles.disabled)}>
      <CheckIcon size="s" aria-hidden={true} className={styles.checkmark} />
    </div>
  </div>
);
