/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCalendarTypename = "PharmacyCalendar" as const;

/**
 *
 * @export
 * @interface PharmacyCalendar
 */
export interface PharmacyCalendar {
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyCalendar
   */
  counselingHours: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PharmacyCalendar
   */
  date: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyCalendar
   */
  dispensingHours: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyCalendar
   */
  publicHoliday: boolean;
  /**
   *
   * @type {string}
   * @memberof PharmacyCalendar
   */
  status: PharmacyCalendarStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyCalendar
   */
  weekday: PharmacyCalendarWeekdayEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCalendar
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCalendarStatusEnum {
  RegularOpeningAll = "regular_opening_all",
  RegularClosedAll = "regular_closed_all",
  TemporaryClosedAll = "temporary_closed_all",
  TemporaryHours = "temporary_hours",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyCalendarWeekdayEnum {
  Sun = "Sun",
  Mon = "Mon",
  Tue = "Tue",
  Wed = "Wed",
  Thu = "Thu",
  Fri = "Fri",
  Sat = "Sat",
}

export function PharmacyCalendarFromJSON(json: any): PharmacyCalendar {
  return PharmacyCalendarFromJSONTyped(json, false);
}

export function PharmacyCalendarFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyCalendar {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCalendarTypename,

    counselingHours: json["counseling_hours"],
    date: json["date"],
    dispensingHours: json["dispensing_hours"],
    publicHoliday: json["public_holiday"],
    status: json["status"],
    weekday: json["weekday"],
  };
}

export function PharmacyCalendarToJSON(object?: PharmacyCalendar | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    counseling_hours: value.counselingHours,
    date: value.date,
    dispensing_hours: value.dispensingHours,
    public_holiday: value.publicHoliday,
    status: value.status,
    weekday: value.weekday,
  };
}
