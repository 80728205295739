import patientApi from "@mgdx/api/lib/patientApi";
import { Patient } from "@mgdx/shared/src/models/Patient";
import { apiResponseToError, firebaseErrorReport } from "@mgdx-libs/error-handler";
import { getAuthedFirebaseUserToken, setAccessToken } from "@mgdx-libs/firebase";
import { User } from "firebase/auth";
import { Dispatch } from "redux";

import { setCurrentUserWithDispatch } from "../../ducks/currentUser";

export const getPatientFromFirebaseUserAndSetToStorage = async (
  firebaseUser: User,
  dispatch: Dispatch
): Promise<Patient> => {
  const result = await getAuthedFirebaseUserToken(firebaseUser).catch((tokenError: Error) => {
    firebaseErrorReport(tokenError);
    throw tokenError;
  });

  setAccessToken(result.token);

  const patient = await patientApi.getCurrentPatient().catch(async (errorOrResponse) => {
    throw await apiResponseToError(errorOrResponse);
  });

  setCurrentUserWithDispatch(dispatch, patient);

  return patient;
};
