/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX bff-patient API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const BffPatientPatientTypename = "BffPatientPatient" as const;

/**
 *
 * @export
 * @interface BffPatientPatient
 */
export interface BffPatientPatient {
  /**
   *
   * @type {Date}
   * @memberof BffPatientPatient
   */
  deletedAt?: string;
  /**
   *
   * @type {boolean}
   * @memberof BffPatientPatient
   */
  hasLineAccount?: boolean;
  /**
   *
   * @type {number}
   * @memberof BffPatientPatient
   */
  id: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof BffPatientPatient
   */
  readonly __typename?: string;
}

export function BffPatientPatientFromJSON(json: any): BffPatientPatient {
  return BffPatientPatientFromJSONTyped(json, false);
}

export function BffPatientPatientFromJSONTyped(json: any, ignoreDiscriminator: boolean): BffPatientPatient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: BffPatientPatientTypename,

    deletedAt:
      !exists(json, "deleted_at") ||
      (typeof json["deleted_at"] === "string" && json["deleted_at"].substring(0, 4) === "0001")
        ? undefined
        : json["deleted_at"],
    hasLineAccount: !exists(json, "has_line_account") ? undefined : json["has_line_account"],
    id: json["id"],
  };
}

export function BffPatientPatientToJSON(object?: BffPatientPatient | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    deleted_at: value.deletedAt === undefined ? undefined : moment(value.deletedAt).tz("Asia/Tokyo").format(),
    has_line_account: value.hasLineAccount,
    id: value.id,
  };
}
