/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientPatientReceiptAssociation,
  PatientPatientReceiptAssociationFromJSON,
  PatientPatientReceiptAssociationFromJSONTyped,
  PatientPatientReceiptAssociationToJSON,
} from "./";

export const PatientPatientReceiptAssociationsTypename = "PatientPatientReceiptAssociations" as const;

/**
 *
 * @export
 * @interface PatientPatientReceiptAssociations
 */
export interface PatientPatientReceiptAssociations {
  /**
   *
   * @type {number}
   * @memberof PatientPatientReceiptAssociations
   */
  count?: number;
  /**
   *
   * @type {Array<PatientPatientReceiptAssociation>}
   * @memberof PatientPatientReceiptAssociations
   */
  patientReceiptAssociations?: Array<PatientPatientReceiptAssociation>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientPatientReceiptAssociations
   */
  readonly __typename?: string;
}

export function PatientPatientReceiptAssociationsFromJSON(json: any): PatientPatientReceiptAssociations {
  return PatientPatientReceiptAssociationsFromJSONTyped(json, false);
}

export function PatientPatientReceiptAssociationsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientPatientReceiptAssociations {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientPatientReceiptAssociationsTypename,

    count: !exists(json, "count") ? undefined : json["count"],
    patientReceiptAssociations: !exists(json, "patient_receipt_associations")
      ? undefined
      : (json["patient_receipt_associations"] as Array<any>).map(PatientPatientReceiptAssociationFromJSON),
  };
}

export function PatientPatientReceiptAssociationsToJSON(object?: PatientPatientReceiptAssociations | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    count: value.count,
    patient_receipt_associations:
      value.patientReceiptAssociations === undefined
        ? undefined
        : (value.patientReceiptAssociations as Array<any>).map(PatientPatientReceiptAssociationToJSON),
  };
}
