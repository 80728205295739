/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientMedicineAlarmSetting,
  PatientMedicineAlarmSettingFromJSON,
  PatientMedicineAlarmSettingFromJSONTyped,
  PatientMedicineAlarmSettingToJSON,
} from "./";

export const GetMedicineAlarmSettingsResponseTypename = "GetMedicineAlarmSettingsResponse" as const;

/**
 *
 * @export
 * @interface GetMedicineAlarmSettingsResponse
 */
export interface GetMedicineAlarmSettingsResponse {
  /**
   *
   * @type {number}
   * @memberof GetMedicineAlarmSettingsResponse
   */
  count?: number;
  /**
   *
   * @type {Array<PatientMedicineAlarmSetting>}
   * @memberof GetMedicineAlarmSettingsResponse
   */
  medicineAlarmSettings?: Array<PatientMedicineAlarmSetting>;
  /**
   * Resource type name
   * @type {string}
   * @memberof GetMedicineAlarmSettingsResponse
   */
  readonly __typename?: string;
}

export function GetMedicineAlarmSettingsResponseFromJSON(json: any): GetMedicineAlarmSettingsResponse {
  return GetMedicineAlarmSettingsResponseFromJSONTyped(json, false);
}

export function GetMedicineAlarmSettingsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetMedicineAlarmSettingsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: GetMedicineAlarmSettingsResponseTypename,

    count: !exists(json, "count") ? undefined : json["count"],
    medicineAlarmSettings: !exists(json, "medicine_alarm_settings")
      ? undefined
      : (json["medicine_alarm_settings"] as Array<any>).map(PatientMedicineAlarmSettingFromJSON),
  };
}

export function GetMedicineAlarmSettingsResponseToJSON(object?: GetMedicineAlarmSettingsResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    count: value.count,
    medicine_alarm_settings:
      value.medicineAlarmSettings === undefined
        ? undefined
        : (value.medicineAlarmSettings as Array<any>).map(PatientMedicineAlarmSettingToJSON),
  };
}
