/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientReceiptPatientRegistrable,
  PatientReceiptPatientRegistrableFromJSON,
  PatientReceiptPatientRegistrableFromJSONTyped,
  PatientReceiptPatientRegistrableToJSON,
} from "./";

export const PostPatientReceiptAssociationRequestBodyTypename = "PostPatientReceiptAssociationRequestBody" as const;

/**
 *
 * @export
 * @interface PostPatientReceiptAssociationRequestBody
 */
export interface PostPatientReceiptAssociationRequestBody {
  /**
   *
   * @type {number}
   * @memberof PostPatientReceiptAssociationRequestBody
   */
  patientId: number;
  /**
   *
   * @type {PatientReceiptPatientRegistrable}
   * @memberof PostPatientReceiptAssociationRequestBody
   */
  receiptPatient: PatientReceiptPatientRegistrable;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostPatientReceiptAssociationRequestBody
   */
  readonly __typename?: string;
}

export function PostPatientReceiptAssociationRequestBodyFromJSON(json: any): PostPatientReceiptAssociationRequestBody {
  return PostPatientReceiptAssociationRequestBodyFromJSONTyped(json, false);
}

export function PostPatientReceiptAssociationRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostPatientReceiptAssociationRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostPatientReceiptAssociationRequestBodyTypename,

    patientId: json["patient_id"],
    receiptPatient: PatientReceiptPatientRegistrableFromJSON(json["receipt_patient"]),
  };
}

export function PostPatientReceiptAssociationRequestBodyToJSON(
  object?: PostPatientReceiptAssociationRequestBody | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    patient_id: value.patientId,
    receipt_patient: PatientReceiptPatientRegistrableToJSON(value.receiptPatient),
  };
}
