import clsx from "clsx";
import React, { Fragment } from "react";

import { InputCheckbox, InputCheckboxProps } from "../InputCheckbox";
import * as styles from "./ToggleInputGroup.module.css";
import { ToggleInputGroupBase, ToggleInputGroupBaseProps } from "./ToggleInputGroupBase";

export type CheckboxGroupOptionsProps = Pick<
  InputCheckboxProps,
  "value" | "checked" | "name" | "disabled" | "onChange"
> & {
  label: React.ReactNode;
  "data-testid"?: string;
};

export type ToggleInputGroupCheckboxProps = Pick<InputCheckboxProps, "name" | "value" | "onChange"> &
  Pick<
    ToggleInputGroupBaseProps,
    "label" | "required" | "disabled" | "completed" | "error" | "message" | "noMessageArea" | "className"
  > & {
    checkboxGroupOptions: CheckboxGroupOptionsProps[];
  };

export const ToggleInputGroupCheckbox: React.FC<ToggleInputGroupCheckboxProps> = ({
  /* ToggleInputGroupBaseProps */
  label,
  required,
  disabled,
  completed,
  error,
  message,
  noMessageArea,
  className,
  /* InputCheckboxProps */
  name,
  onChange,
  value,
  /* my props */
  checkboxGroupOptions,
  ...props
}) => (
  <ToggleInputGroupBase
    label={label}
    error={error}
    message={message}
    required={required}
    disabled={disabled}
    noMessageArea={noMessageArea}
    className={className}
  >
    {checkboxGroupOptions.map(
      (
        {
          name: optionName,
          label: optionLabel,
          value: optionValue,
          disabled: optionDisabled,
          onChange: onOptionChange,
          ...option
        },
        index
      ) => (
        <Fragment key={`item${index}`}>
          <InputCheckbox
            {...props}
            name={optionName || name}
            value={optionValue}
            checked={Array.isArray(value) ? value.indexOf(optionValue) >= 0 : option.checked}
            completed={completed}
            disabled={optionDisabled ?? disabled}
            widePadding={true}
            nowrap={false}
            onChange={onOptionChange || onChange}
            className={clsx(
              styles.checkbox,
              index !== 0 && styles.divider,
              !disabled && optionDisabled && styles.disabled
            )}
            data-testid={option["data-testid"]}
          >
            {optionLabel}
          </InputCheckbox>
        </Fragment>
      )
    )}
  </ToggleInputGroupBase>
);
