import { createQueryKeyStore } from "@lukemorales/query-key-factory";

export const patientKeys = createQueryKeyStore({
  patient: {
    current: null,
  },
  notifyTypeByChain: {
    detail: (chainId) => [chainId],
  },
});
