import { createQueryKeyStore } from "@lukemorales/query-key-factory";

import { MedicineNotebookAccountRequestConfig } from "../providers/MedicineNotebookAccountProvider";

export const medicineNotebookKeys = createQueryKeyStore({
  medicineNotebook: {
    detail: (requestConfig: MedicineNotebookAccountRequestConfig) => [requestConfig],
    mccmJahisSync: (chainId: number, canMigrationEsmMedicineNotebook: boolean) => [
      chainId,
      canMigrationEsmMedicineNotebook,
    ],
    esmQrcodeCounts: (isMccmEnv: boolean) => [isMccmEnv],
  },
});
