// API ErrorCode
// @see https://car.docbase.io/posts/2100851
//
import { PharmacyCounselorCustomBadRequest3DetailCodeEnum } from "@mgdx/api/lib/pharmacy/counselor";

export enum ApiErrorCodeEnum {
  ExistsCounseling = "B-PATIENT-E001",
  InvalidClinicIpAddr = "CLINIC-E001",
  InvalidClinicPreferedDate = "C-COUNSELOR-E001",
  ClinicEmailNotVerified = "C-COUNSELOR-E002",
  ConflictClinicReserved = "C-COUNSELOR-E003",
  ConflictMedicineNotebook = "PATIENT-E001",
  InvalidCreditCard = "PAYER-E001",
  ConflictPayment = "PAYER-E002",
  InvalidPharmacyPreferedDate = "P-COUNSELOR-E001",
  PharmacyEmailNotVerified = "P-COUNSELOR-E002",
  ImageSizeTooLarge = "P-COUNSELOR-E003",
  CreditCardUnregisteredDetailCode = "P-COUNSELOR-E004",
  AlreadyUsedReceiptCode = "P-COUNSELOR-E005",
  InvalidDateOfBirthDetailCode = "P-COUNSELOR-E006",
  ReservationDuplication = "P-COUNSELOR-E007",
  InvalidPharmacyIpAddr = "PHARMACY-E001",
  BulkUploadDetailCode = "PHARMACY-E002",
  InvalidDisplayNumber = "PHARMACY-E003",
  ConflictPatientAccount = "Patient-Patient-E001",
  PatientAlreadyUsedReceiptCode = "Patient-Patient-E002",
  InvalidPatientPassword = "Patient-Patient-E003",
  AlreadyUsedMedicineNotebookShareCode = "Patient-Patient-E004",
  AlreadyExpiredMedicineNotebookShareCode = "Patient-Patient-E005",
  ExceedMedicineNotebookShareCapacity = "Patient-Patient-E006",
  NotFoundChainMedicineNotebook = "Patient-Patient-E007",
  MedicineNotebookShareWithMyself = "Patient-Patient-E008",
  EmailFormatIsInvalid = "Patient-Patient-E009",
  DisableUpdateUserInfoWithMeditineNotebookAutoLinked = "Patient-Patient-E010",
  NotExistsMedicineNotebookRecords = "Patient-Patient-E011",
  InvalidNetUserId = "Patient-Patient-E012",
  InvalidBirthdayEsmUserMigration = "Patient-Patient-E015",
  UseCurrentQuestionnairePatternInFollowUpPattern = "PHARMACY-E004",
  InvalidBirthdayEsmMedicineNotebookMigration = "B-PATIENT-E002",
}

// Delivery API ErrorCode
// @see https://car.docbase.io/posts/2905466
//
export enum DeliveryErrorCodeEnum {
  OutsideBusinessHours = "DELIVERER-E001-OutsideBusinessHours",
  ExceedMaxDeliverableDistance = "DELIVERER-E002-ExceedMaxDeliverableDistance",
  InvalidDropoffAddress = "DELIVERER-E003-InvalidDropoffAddress",
  PickupOutsideDeliverableArea = "DELIVERER-E004-PickupOutsideDeliverableArea",
  DropoffOutsideDeliverableArea = "DELIVERER-E005-DropoffOutsideDeliverableArea",
  InvalidScheduledDropoffTime = "DELIVERER-E006-InvalidScheduledDropoffTime",
  DuplicatedDeliveryOrder = "DELIVERER-E007-DuplicatedDeliveryOrder",
  InvalidProviderConfiguration = "DELIVERER-E008-InvalidProviderConfiguration",
  UnprocessableInput = "DELIVERER-E009-UnprocessableInput",
  InvalidAddress = "DELIVERER-E010-InvalidAddress",
  InvalidPickupAddress = "DELIVERER-E011-InvalidPickupAddress",
  InvalidDropoffPhoneNumber = "DELIVERER-E012-InvalidDropoffPhoneNumber",
  InvalidScheduledPickupTime = "DELIVERER-E013-InvalidScheduledPickupTime",
}

type GetApiErrorCodeEnumToString = (errorCode?: ApiErrorCodeEnum | DeliveryErrorCodeEnum | string) => string;

export const getApiErrorCodeEnumToString: GetApiErrorCodeEnumToString = (errorCode) => {
  if (errorCode === ApiErrorCodeEnum.InvalidClinicIpAddr || errorCode === ApiErrorCodeEnum.InvalidPharmacyIpAddr) {
    return "許可されたネットワークではありません。";
  }

  if (
    errorCode === ApiErrorCodeEnum.ClinicEmailNotVerified ||
    errorCode === ApiErrorCodeEnum.PharmacyEmailNotVerified
  ) {
    return "メール認証が完了していません。";
  }

  if (
    errorCode === ApiErrorCodeEnum.InvalidClinicPreferedDate ||
    errorCode === ApiErrorCodeEnum.InvalidPharmacyPreferedDate
  ) {
    return "予約できる日付ではありません";
  }

  if (errorCode === ApiErrorCodeEnum.ConflictMedicineNotebook) {
    return "お薬手帳が重複しています。";
  }

  if (errorCode === ApiErrorCodeEnum.InvalidCreditCard) {
    return "クレジットカードが正しくありません。";
  }

  if (errorCode === ApiErrorCodeEnum.ConflictPayment) {
    return "既に支払い済みです。";
  }

  if (errorCode === ApiErrorCodeEnum.ImageSizeTooLarge) {
    return "画像の送信上限を超えたので、画像を減らしてください。追加したい場合は、別の予約で送信してください。";
  }

  if (errorCode === ApiErrorCodeEnum.CreditCardUnregisteredDetailCode) {
    return "クレジットカードが登録されていません。";
  }
  if (
    errorCode === ApiErrorCodeEnum.AlreadyUsedReceiptCode ||
    errorCode === ApiErrorCodeEnum.PatientAlreadyUsedReceiptCode
  ) {
    return "既に使用されているレセコン患者番号です。";
  }
  if (errorCode === ApiErrorCodeEnum.InvalidDateOfBirthDetailCode) {
    return "誕生日が正しく設定されていません。会員情報を確認してください。";
  }

  if (errorCode === ApiErrorCodeEnum.ReservationDuplication) {
    return "重複する予約があります。予約履歴から予約状況をご確認ください。予約操作は1分以上の間隔を空けてください。";
  }

  if (errorCode === ApiErrorCodeEnum.ConflictClinicReserved) {
    return "既に予約済みのため、予約できません。";
  }

  if (errorCode === ApiErrorCodeEnum.ExistsCounseling) {
    return "未完了の予約があるため退会できません";
  }

  if (errorCode === ApiErrorCodeEnum.ConflictPatientAccount) {
    return "入力されたメールアドレスのアカウントは既に存在します。";
  }

  if (errorCode === ApiErrorCodeEnum.InvalidPatientPassword) {
    return "パスワードは半角9桁以上で、アルファベット大文字、小文字、数字、記号の全てを含めてください。";
  }

  if (errorCode === ApiErrorCodeEnum.InvalidDisplayNumber) {
    return "表示順序が重複してします。";
  }

  if (errorCode === ApiErrorCodeEnum.BulkUploadDetailCode) {
    return "一括登録に失敗しました。";
  }

  if (errorCode === ApiErrorCodeEnum.AlreadyUsedMedicineNotebookShareCode) {
    return "入力された共有コードは既に使われています。共有されたお相手に共有コードを確認してください。";
  }

  if (errorCode === ApiErrorCodeEnum.AlreadyExpiredMedicineNotebookShareCode) {
    return "入力された共有コードは、既に有効期限を過ぎています。再度共有コードの発行を依頼してください。";
  }

  if (errorCode === ApiErrorCodeEnum.ExceedMedicineNotebookShareCapacity) {
    return "共有可能な最大人数の15人を超えます。先に他の共有済み情報を解除し、再度共有コードの発行を依頼してください。";
  }

  if (errorCode === ApiErrorCodeEnum.MedicineNotebookShareWithMyself) {
    return "自身に共有申請をしています。共有したい相手に共有コードを伝えてください。";
  }

  if (errorCode === ApiErrorCodeEnum.EmailFormatIsInvalid) {
    return "入力されたメールアドレスを確認してください。";
  }

  if (errorCode === ApiErrorCodeEnum.DisableUpdateUserInfoWithMeditineNotebookAutoLinked) {
    return "お薬の自動登録済みのため、会員情報の変更はできません。";
  }

  if (errorCode === ApiErrorCodeEnum.InvalidNetUserId) {
    return "アプリと薬急便のアカウントが異なっています。";
  }

  if (errorCode === ApiErrorCodeEnum.UseCurrentQuestionnairePatternInFollowUpPattern) {
    return "FUパターンで使用されています。";
  }

  if (errorCode === DeliveryErrorCodeEnum.OutsideBusinessHours) {
    return "営業時間外です。";
  }

  if (errorCode === DeliveryErrorCodeEnum.ExceedMaxDeliverableDistance) {
    return "最大配達距離(4km)を超えています。配達距離をご確認ください。";
  }

  if (errorCode === DeliveryErrorCodeEnum.ExceedMaxDeliverableDistance) {
    return "最大配達距離(4km)を超えています。配達距離をご確認ください。";
  }

  if (errorCode === DeliveryErrorCodeEnum.InvalidDropoffAddress) {
    return "お届け先の住所が正しいかご確認ください。";
  }

  if (errorCode === DeliveryErrorCodeEnum.PickupOutsideDeliverableArea) {
    return "集荷先の住所がエリア範囲外です。";
  }

  if (errorCode === DeliveryErrorCodeEnum.DropoffOutsideDeliverableArea) {
    return "お届け先の住所がエリア範囲外です。";
  }

  if (errorCode === DeliveryErrorCodeEnum.InvalidScheduledDropoffTime) {
    return "お届け希望日時は集荷日時より十分間隔を取って選択してください。";
  }

  if (errorCode === DeliveryErrorCodeEnum.DuplicatedDeliveryOrder) {
    return "注文番号の指定に誤りがある為オーダーできません。運営元にご連絡ください。";
  }

  if (errorCode === DeliveryErrorCodeEnum.InvalidProviderConfiguration) {
    return "システム設定に誤りがある為オーダーできません。運営元にご連絡ください。";
  }

  if (errorCode === DeliveryErrorCodeEnum.UnprocessableInput) {
    return "入力内容に誤りがある為オーダーできません。";
  }

  if (errorCode === DeliveryErrorCodeEnum.InvalidAddress) {
    return "集荷先またはお届け先住所が不正です。";
  }

  if (errorCode === DeliveryErrorCodeEnum.InvalidPickupAddress) {
    return "集荷先住所が不正です。";
  }

  if (errorCode === DeliveryErrorCodeEnum.InvalidDropoffPhoneNumber) {
    return "お届け先電話番号が不正です。";
  }

  if (errorCode === DeliveryErrorCodeEnum.InvalidScheduledPickupTime) {
    return "集荷できない時間帯です。";
  }

  if (errorCode === PharmacyCounselorCustomBadRequest3DetailCodeEnum.PCounselorE005) {
    return "患者番号は既に使われています。";
  }

  if (
    errorCode === ApiErrorCodeEnum.InvalidBirthdayEsmMedicineNotebookMigration ||
    errorCode === ApiErrorCodeEnum.InvalidBirthdayEsmUserMigration
  ) {
    return "生年月日が一致しません。入力内容を確認してください。";
  }

  return "";
};

export const getApiErrorMessage = async (error: Response) => {
  const json = await error.json();
  if (!json) return;

  return getApiErrorCodeEnumToString(json.detail_code);
};
