import * as Sentry from "@sentry/react";
import toString from "lodash/toString";
import React from "react";

export const ErrorFallbackSentry = ({ error, resetError }: Parameters<Sentry.FallbackRender>[0]) => (
  <div role="alert">
    <p>Error Message</p>
    <pre>{toString(error)}</pre>
    <button onClick={resetError}>Try again</button>
  </div>
);
