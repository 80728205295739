const IS_IN_MCCM_APP_STORAGE_KEY = "isInMccmApp";

const hasSessionStorage = (): boolean => typeof window !== "undefined" && typeof window.sessionStorage !== "undefined";

export const isInMccmApp = (): boolean => {
  if (hasSessionStorage()) {
    return sessionStorage.getItem(IS_IN_MCCM_APP_STORAGE_KEY) === "yes" || false;
  }

  return false;
};

export const setIsInMccmApp = (isInMccmApp: boolean): void => {
  if (hasSessionStorage()) {
    sessionStorage.setItem(IS_IN_MCCM_APP_STORAGE_KEY, isInMccmApp ? "yes" : "no");
  }
};

export const clearIsInMccmApp = (): void => {
  if (hasSessionStorage()) {
    sessionStorage.removeItem(IS_IN_MCCM_APP_STORAGE_KEY);
  }
};
