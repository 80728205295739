import clsx from "clsx";
import React from "react";

import * as styles from "./SvgIcon.module.css";

export const sizes = ["xs", "s", "m", "xm", "xxm", "l"] as const;

export const variants = ["primary", "secondary", "tertiary", "text_link", "key"] as const;

export type Props = React.SVGProps<SVGSVGElement> & {
  size?: (typeof sizes)[number];
  variant?: (typeof variants)[number];
};

const DeleteIcon: React.FC<Props> = ({ className, size = "m", variant, ...props }) => {
  const fill = props.fill ?? "inherit";
  return (
    <svg
      fill={fill}
      role={props["aria-label"] ? "img" : undefined}
      aria-hidden={props["aria-hidden"] ?? !props["aria-label"] ? true : undefined}
      className={clsx(
        size === "xs" && styles.iconSizeXS,
        size === "s" && styles.iconSizeS,
        size === "m" && styles.iconSizeM,
        size === "xm" && styles.iconSizeXM,
        size === "xxm" && styles.iconSizeXXM,
        size === "l" && styles.iconSizeL,
        variant === "primary" && styles.iconPrimary,
        variant === "secondary" && styles.iconSecondary,
        variant === "tertiary" && styles.iconTertiary,
        variant === "text_link" && styles.iconLink,
        variant === "key" && styles.iconKey,
        className
      )}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 5.5V4.5C16 3.39543 15.1046 2.5 14 2.5H10C8.89543 2.5 8 3.39543 8 4.5V5.5H10V4.5H14V5.5H16ZM5 6.5H19C19.5523 6.5 20 6.94772 20 7.5C20 8.05228 19.5523 8.5 19 8.5H18V19.5C18 20.6046 17.1046 21.5 16 21.5H8C6.89543 21.5 6 20.6046 6 19.5V8.5H5C4.44772 8.5 4 8.05228 4 7.5C4 6.94772 4.44772 6.5 5 6.5ZM10 10.5C9.58579 10.5 9.25 10.8358 9.25 11.25V17.75C9.25 18.1642 9.58579 18.5 10 18.5C10.4142 18.5 10.75 18.1642 10.75 17.75V11.25C10.75 10.8358 10.4142 10.5 10 10.5ZM13.25 11.25C13.25 10.8358 13.5858 10.5 14 10.5C14.4142 10.5 14.75 10.8358 14.75 11.25V17.75C14.75 18.1642 14.4142 18.5 14 18.5C13.5858 18.5 13.25 18.1642 13.25 17.75V11.25Z"
      />
    </svg>
  );
};

export default DeleteIcon;
