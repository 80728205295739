import clsx from "clsx";
import React from "react";

import * as styles from "./SvgIcon.module.css";

export const sizes = ["xs", "s", "m", "xm", "xxm", "l"] as const;

export const variants = ["primary", "secondary", "tertiary", "text_link", "key"] as const;

export type Props = React.SVGProps<SVGSVGElement> & {
  size?: (typeof sizes)[number];
  variant?: (typeof variants)[number];
};

const FileImageIcon: React.FC<Props> = ({ className, size = "m", variant, ...props }) => {
  const fill = props.fill ?? "inherit";
  return (
    <svg
      fill={fill}
      role={props["aria-label"] ? "img" : undefined}
      aria-hidden={props["aria-hidden"] ?? !props["aria-label"] ? true : undefined}
      className={clsx(
        size === "xs" && styles.iconSizeXS,
        size === "s" && styles.iconSizeS,
        size === "m" && styles.iconSizeM,
        size === "xm" && styles.iconSizeXM,
        size === "xxm" && styles.iconSizeXXM,
        size === "l" && styles.iconSizeL,
        variant === "primary" && styles.iconPrimary,
        variant === "secondary" && styles.iconSecondary,
        variant === "tertiary" && styles.iconTertiary,
        variant === "text_link" && styles.iconLink,
        variant === "key" && styles.iconKey,
        className
      )}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5C2 3.89543 2.89543 3 4 3H20C21.1046 3 22 3.89543 22 5V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V5ZM8.16253 14.0367L10.7488 17.1526L14.5666 12.2386C14.8129 11.9184 15.3056 11.9184 15.5519 12.251L19.8747 18.0146C20.1825 18.4211 19.887 18.9999 19.382 18.9999H4.61565C4.09839 18.9999 3.81513 18.4087 4.13534 18.0023L7.20192 14.0613C7.43591 13.7411 7.90391 13.7288 8.16253 14.0367ZM8 6.75C7.03321 6.75 6.25 7.53321 6.25 8.5C6.25 9.46679 7.03321 10.25 8 10.25C8.96679 10.25 9.75 9.46679 9.75 8.5C9.75 7.53321 8.96679 6.75 8 6.75Z"
      />
    </svg>
  );
};

export default FileImageIcon;
