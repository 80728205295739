import clsx from "clsx";
import React from "react";

import * as styles from "./SvgIcon.module.css";

export const sizes = ["xs", "s", "m", "xm", "xxm", "l"] as const;

export const variants = ["primary", "secondary", "tertiary", "text_link", "key"] as const;

export type Props = React.SVGProps<SVGSVGElement> & {
  size?: (typeof sizes)[number];
  variant?: (typeof variants)[number];
};

const CloseIcon: React.FC<Props> = ({ className, size = "m", variant, ...props }) => {
  const fill = props.fill ?? "inherit";
  return (
    <svg
      fill={fill}
      role={props["aria-label"] ? "img" : undefined}
      aria-hidden={props["aria-hidden"] ?? !props["aria-label"] ? true : undefined}
      className={clsx(
        size === "xs" && styles.iconSizeXS,
        size === "s" && styles.iconSizeS,
        size === "m" && styles.iconSizeM,
        size === "xm" && styles.iconSizeXM,
        size === "xxm" && styles.iconSizeXXM,
        size === "l" && styles.iconSizeL,
        variant === "primary" && styles.iconPrimary,
        variant === "secondary" && styles.iconSecondary,
        variant === "tertiary" && styles.iconTertiary,
        variant === "text_link" && styles.iconLink,
        variant === "key" && styles.iconKey,
        className
      )}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M4.39985 4.39973C4.10695 4.69262 4.10695 5.16749 4.39985 5.46039L10.9406 12.0011L4.39985 18.5419C4.10696 18.8348 4.10696 19.3096 4.39985 19.6025C4.69274 19.8954 5.16762 19.8954 5.46051 19.6025L12.0012 13.0618L18.542 19.6025C18.8349 19.8954 19.3097 19.8954 19.6026 19.6025C19.8955 19.3096 19.8955 18.8348 19.6026 18.5419L13.0619 12.0011L19.6026 5.46038C19.8955 5.16749 19.8955 4.69262 19.6026 4.39972C19.3098 4.10683 18.8349 4.10683 18.542 4.39972L12.0012 10.9405L5.46051 4.39973C5.16761 4.10683 4.69274 4.10683 4.39985 4.39973Z" />
    </svg>
  );
};

export default CloseIcon;
