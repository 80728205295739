/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const GetMCCMEMSQrCodeCountsResponseTypename = "GetMCCMEMSQrCodeCountsResponse" as const;

/**
 *
 * @export
 * @interface GetMCCMEMSQrCodeCountsResponse
 */
export interface GetMCCMEMSQrCodeCountsResponse {
  /**
   *
   * @type {number}
   * @memberof GetMCCMEMSQrCodeCountsResponse
   */
  count?: number;
  /**
   *
   * @type {object}
   * @memberof GetMCCMEMSQrCodeCountsResponse
   */
  familyAccountCounts?: object;
  /**
   * Resource type name
   * @type {string}
   * @memberof GetMCCMEMSQrCodeCountsResponse
   */
  readonly __typename?: string;
}

export function GetMCCMEMSQrCodeCountsResponseFromJSON(json: any): GetMCCMEMSQrCodeCountsResponse {
  return GetMCCMEMSQrCodeCountsResponseFromJSONTyped(json, false);
}

export function GetMCCMEMSQrCodeCountsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetMCCMEMSQrCodeCountsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: GetMCCMEMSQrCodeCountsResponseTypename,

    count: !exists(json, "count") ? undefined : json["count"],
    familyAccountCounts: !exists(json, "family_account_counts") ? undefined : json["family_account_counts"],
  };
}

export function GetMCCMEMSQrCodeCountsResponseToJSON(object?: GetMCCMEMSQrCodeCountsResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    count: value.count,
    family_account_counts: value.familyAccountCounts,
  };
}
