/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PatchBookmarksRequestBody,
  PatchBookmarksRequestBodyFromJSON,
  PatchBookmarksRequestBodyToJSON,
  PharmacyBookmark,
  PharmacyBookmarkFromJSON,
  PharmacyBookmarkToJSON,
  PharmacyChain,
  PharmacyChainFromJSON,
  PharmacyChainToJSON,
  PharmacyChainRegistrable,
  PharmacyChainRegistrableFromJSON,
  PharmacyChainRegistrableToJSON,
  PharmacyChainUpdatable,
  PharmacyChainUpdatableFromJSON,
  PharmacyChainUpdatableToJSON,
  PharmacyChainUsageSummary,
  PharmacyChainUsageSummaryFromJSON,
  PharmacyChainUsageSummaryToJSON,
  PharmacyCustomBadRequest,
  PharmacyCustomBadRequestFromJSON,
  PharmacyCustomBadRequestToJSON,
  PharmacyFollowUpNotificationPattern,
  PharmacyFollowUpNotificationPatternFromJSON,
  PharmacyFollowUpNotificationPatternToJSON,
  PharmacyFollowUpNotificationPatternRegistrable,
  PharmacyFollowUpNotificationPatternRegistrableFromJSON,
  PharmacyFollowUpNotificationPatternRegistrableToJSON,
  PharmacyFollowUpNotificationPatternUpdatable,
  PharmacyFollowUpNotificationPatternUpdatableFromJSON,
  PharmacyFollowUpNotificationPatternUpdatableToJSON,
  PharmacyFollowUpQuestionnairePattern,
  PharmacyFollowUpQuestionnairePatternFromJSON,
  PharmacyFollowUpQuestionnairePatternToJSON,
  PharmacyFollowUpQuestionnairePatternRegistrable,
  PharmacyFollowUpQuestionnairePatternRegistrableFromJSON,
  PharmacyFollowUpQuestionnairePatternRegistrableToJSON,
  PharmacyFollowUpQuestionnairePatternUpdatable,
  PharmacyFollowUpQuestionnairePatternUpdatableFromJSON,
  PharmacyFollowUpQuestionnairePatternUpdatableToJSON,
  PharmacyPharmacyCount,
  PharmacyPharmacyCountFromJSON,
  PharmacyPharmacyCountToJSON,
} from "../models";

export interface CreateChainRequest {
  pharmacyChainRegistrable: Array<PharmacyChainRegistrable>;
}

export interface DeleteChainFollowUpNotificationPatternRequest {
  chainId: number;
  id: number;
}

export interface DeleteChainFollowUpQuestionnairePatternRequest {
  chainId: number;
  id: number;
}

export interface GetAllChainRequest {
  appName?: string;
}

export interface GetBookmarksRequest {
  chainId: number;
}

export interface GetChainRequest {
  chainId: number;
}

export interface GetChainFollowUpNotificationPatternRequest {
  chainId: number;
  id: number;
}

export interface GetChainFollowUpNotificationPatternsRequest {
  chainId: number;
}

export interface GetChainFollowUpQuestionnairePatternRequest {
  chainId: number;
  id: number;
}

export interface GetChainFollowUpQuestionnairePatternsRequest {
  chainId: number;
}

export interface GetChainPharmacyCalendarsRequest {
  chainId: number;
  from: string;
  to: string;
}

export interface GetChainPharmacyRecurringCalendarsCsvRequest {
  chainId: number;
}

export interface GetChainUsageSummariesRequest {
  chainId: number;
  month: string;
  cumulate?: boolean;
}

export interface GetPharmacyCountsRequest {
  isScreening?: boolean;
  visibility?: Array<GetPharmacyCountsVisibilityEnum>;
}

export interface PostChainFollowUpNotificationPatternRequest {
  chainId: number;
  pharmacyFollowUpNotificationPatternRegistrable: PharmacyFollowUpNotificationPatternRegistrable;
}

export interface PostChainFollowUpQuestionnairePatternRequest {
  chainId: number;
  pharmacyFollowUpQuestionnairePatternRegistrable: PharmacyFollowUpQuestionnairePatternRegistrable;
}

export interface PutChainPharmacyRecurringCalendarsCsvRequest {
  chainId: number;
  body: string;
}

export interface UpdateBookmarksRequest {
  chainId: number;
  patchBookmarksRequestBody: PatchBookmarksRequestBody;
}

export interface UpdateChainRequest {
  chainId: number;
  pharmacyChainUpdatable: PharmacyChainUpdatable;
}

export interface UpdateChainFollowUpNotificationPatternRequest {
  chainId: number;
  id: number;
  pharmacyFollowUpNotificationPatternUpdatable: PharmacyFollowUpNotificationPatternUpdatable;
}

export interface UpdateChainFollowUpQuestionnairePatternRequest {
  chainId: number;
  id: number;
  pharmacyFollowUpQuestionnairePatternUpdatable: PharmacyFollowUpQuestionnairePatternUpdatable;
}

/**
 *
 */
export class ChainApi extends runtime.BaseAPI {
  /**
   * Register chain
   */
  async createChainRaw(
    requestParameters: CreateChainRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyChain>>> {
    if (
      requestParameters.pharmacyChainRegistrable === null ||
      requestParameters.pharmacyChainRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyChainRegistrable",
        "Required parameter requestParameters.pharmacyChainRegistrable was null or undefined when calling createChain."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.pharmacyChainRegistrable.map(PharmacyChainRegistrableToJSON),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyChainFromJSON));
  }

  /**
   * Register chain
   */
  async createChain(requestParameters: CreateChainRequest, initOverrides?: RequestInit): Promise<Array<PharmacyChain>> {
    return promiseRetry(
      (retry) =>
        this.createChainRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Delete chain follow up notification patterns
   */
  async deleteChainFollowUpNotificationPatternRaw(
    requestParameters: DeleteChainFollowUpNotificationPatternRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling deleteChainFollowUpNotificationPattern."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteChainFollowUpNotificationPattern."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}/follow-up-notification-patterns/{id}`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete chain follow up notification patterns
   */
  async deleteChainFollowUpNotificationPattern(
    requestParameters: DeleteChainFollowUpNotificationPatternRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deleteChainFollowUpNotificationPatternRaw(requestParameters, initOverrides);
  }

  /**
   * Delete chain follow up questionnaire patterns
   */
  async deleteChainFollowUpQuestionnairePatternRaw(
    requestParameters: DeleteChainFollowUpQuestionnairePatternRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling deleteChainFollowUpQuestionnairePattern."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteChainFollowUpQuestionnairePattern."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}/follow-up-questionnaire-patterns/{id}`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete chain follow up questionnaire patterns
   */
  async deleteChainFollowUpQuestionnairePattern(
    requestParameters: DeleteChainFollowUpQuestionnairePatternRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deleteChainFollowUpQuestionnairePatternRaw(requestParameters, initOverrides);
  }

  /**
   * Get all chain
   */
  async getAllChainRaw(
    requestParameters: GetAllChainRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyChain>>> {
    const queryParameters: any = {};

    if (requestParameters.appName !== undefined) {
      queryParameters["app_name"] = requestParameters.appName;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/chains`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyChainFromJSON));
  }

  /**
   * Get all chain
   */
  async getAllChain(requestParameters: GetAllChainRequest, initOverrides?: RequestInit): Promise<Array<PharmacyChain>> {
    return promiseRetry(
      (retry) =>
        this.getAllChainRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get bookmarks
   */
  async getBookmarksRaw(
    requestParameters: GetBookmarksRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyBookmark>>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getBookmarks."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}/bookmarks`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyBookmarkFromJSON));
  }

  /**
   * Get bookmarks
   */
  async getBookmarks(
    requestParameters: GetBookmarksRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyBookmark>> {
    return promiseRetry(
      (retry) =>
        this.getBookmarksRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get chain
   */
  async getChainRaw(
    requestParameters: GetChainRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyChain>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getChain."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/chains/{chain_id}`.replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyChainFromJSON(jsonValue));
  }

  /**
   * Get chain
   */
  async getChain(requestParameters: GetChainRequest, initOverrides?: RequestInit): Promise<PharmacyChain> {
    return promiseRetry(
      (retry) =>
        this.getChainRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get chain follow up notification pattern
   */
  async getChainFollowUpNotificationPatternRaw(
    requestParameters: GetChainFollowUpNotificationPatternRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyFollowUpNotificationPattern>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getChainFollowUpNotificationPattern."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getChainFollowUpNotificationPattern."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}/follow-up-notification-patterns/{id}`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyFollowUpNotificationPatternFromJSON(jsonValue));
  }

  /**
   * Get chain follow up notification pattern
   */
  async getChainFollowUpNotificationPattern(
    requestParameters: GetChainFollowUpNotificationPatternRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyFollowUpNotificationPattern> {
    return promiseRetry(
      (retry) =>
        this.getChainFollowUpNotificationPatternRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get chain follow up notification patterns
   */
  async getChainFollowUpNotificationPatternsRaw(
    requestParameters: GetChainFollowUpNotificationPatternsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyFollowUpNotificationPattern>>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getChainFollowUpNotificationPatterns."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}/follow-up-notification-patterns`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(PharmacyFollowUpNotificationPatternFromJSON)
    );
  }

  /**
   * Get chain follow up notification patterns
   */
  async getChainFollowUpNotificationPatterns(
    requestParameters: GetChainFollowUpNotificationPatternsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyFollowUpNotificationPattern>> {
    return promiseRetry(
      (retry) =>
        this.getChainFollowUpNotificationPatternsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get chain follow up questionnaire pattern
   */
  async getChainFollowUpQuestionnairePatternRaw(
    requestParameters: GetChainFollowUpQuestionnairePatternRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyFollowUpQuestionnairePattern>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getChainFollowUpQuestionnairePattern."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getChainFollowUpQuestionnairePattern."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}/follow-up-questionnaire-patterns/{id}`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PharmacyFollowUpQuestionnairePatternFromJSON(jsonValue)
    );
  }

  /**
   * Get chain follow up questionnaire pattern
   */
  async getChainFollowUpQuestionnairePattern(
    requestParameters: GetChainFollowUpQuestionnairePatternRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyFollowUpQuestionnairePattern> {
    return promiseRetry(
      (retry) =>
        this.getChainFollowUpQuestionnairePatternRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get chain follow up questionnaire patterns
   */
  async getChainFollowUpQuestionnairePatternsRaw(
    requestParameters: GetChainFollowUpQuestionnairePatternsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyFollowUpQuestionnairePattern>>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getChainFollowUpQuestionnairePatterns."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}/follow-up-questionnaire-patterns`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(PharmacyFollowUpQuestionnairePatternFromJSON)
    );
  }

  /**
   * Get chain follow up questionnaire patterns
   */
  async getChainFollowUpQuestionnairePatterns(
    requestParameters: GetChainFollowUpQuestionnairePatternsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyFollowUpQuestionnairePattern>> {
    return promiseRetry(
      (retry) =>
        this.getChainFollowUpQuestionnairePatternsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get chain\'s pharmacy calendars
   */
  async getChainPharmacyCalendarsRaw(
    requestParameters: GetChainPharmacyCalendarsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<string>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getChainPharmacyCalendars."
      );
    }

    if (requestParameters.from === null || requestParameters.from === undefined) {
      throw new runtime.RequiredError(
        "from",
        "Required parameter requestParameters.from was null or undefined when calling getChainPharmacyCalendars."
      );
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
      throw new runtime.RequiredError(
        "to",
        "Required parameter requestParameters.to was null or undefined when calling getChainPharmacyCalendars."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = requestParameters.from;
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = requestParameters.to;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}/pharmacy-calendars.csv`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Get chain\'s pharmacy calendars
   */
  async getChainPharmacyCalendars(
    requestParameters: GetChainPharmacyCalendarsRequest,
    initOverrides?: RequestInit
  ): Promise<string> {
    return promiseRetry(
      (retry) =>
        this.getChainPharmacyCalendarsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get chain\'s pharmacy recurring calendars
   */
  async getChainPharmacyRecurringCalendarsCsvRaw(
    requestParameters: GetChainPharmacyRecurringCalendarsCsvRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<string>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getChainPharmacyRecurringCalendarsCsv."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}/pharmacy-recurring-calendars.csv`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Get chain\'s pharmacy recurring calendars
   */
  async getChainPharmacyRecurringCalendarsCsv(
    requestParameters: GetChainPharmacyRecurringCalendarsCsvRequest,
    initOverrides?: RequestInit
  ): Promise<string> {
    return promiseRetry(
      (retry) =>
        this.getChainPharmacyRecurringCalendarsCsvRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get chain usage summaries
   */
  async getChainUsageSummariesRaw(
    requestParameters: GetChainUsageSummariesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyChainUsageSummary>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getChainUsageSummaries."
      );
    }

    if (requestParameters.month === null || requestParameters.month === undefined) {
      throw new runtime.RequiredError(
        "month",
        "Required parameter requestParameters.month was null or undefined when calling getChainUsageSummaries."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.chainId !== undefined) {
      queryParameters["chain_id"] = requestParameters.chainId;
    }

    if (requestParameters.month !== undefined) {
      queryParameters["month"] = requestParameters.month;
    }

    if (requestParameters.cumulate !== undefined) {
      queryParameters["cumulate"] = requestParameters.cumulate;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chain-usage-summaries`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyChainUsageSummaryFromJSON(jsonValue));
  }

  /**
   * Get chain usage summaries
   */
  async getChainUsageSummaries(
    requestParameters: GetChainUsageSummariesRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyChainUsageSummary> {
    return promiseRetry(
      (retry) =>
        this.getChainUsageSummariesRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get the number of pharmacies grouped by chain id
   */
  async getPharmacyCountsRaw(
    requestParameters: GetPharmacyCountsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyPharmacyCount>>> {
    const queryParameters: any = {};

    if (requestParameters.isScreening !== undefined) {
      queryParameters["is_screening"] = requestParameters.isScreening;
    }

    if (requestParameters.visibility) {
      queryParameters["visibility"] = requestParameters.visibility;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/chains-pharmacy-counts`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyPharmacyCountFromJSON));
  }

  /**
   * Get the number of pharmacies grouped by chain id
   */
  async getPharmacyCounts(
    requestParameters: GetPharmacyCountsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyPharmacyCount>> {
    return promiseRetry(
      (retry) =>
        this.getPharmacyCountsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register chain follow up notification patterns
   */
  async postChainFollowUpNotificationPatternRaw(
    requestParameters: PostChainFollowUpNotificationPatternRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyFollowUpNotificationPattern>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling postChainFollowUpNotificationPattern."
      );
    }

    if (
      requestParameters.pharmacyFollowUpNotificationPatternRegistrable === null ||
      requestParameters.pharmacyFollowUpNotificationPatternRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyFollowUpNotificationPatternRegistrable",
        "Required parameter requestParameters.pharmacyFollowUpNotificationPatternRegistrable was null or undefined when calling postChainFollowUpNotificationPattern."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}/follow-up-notification-patterns`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyFollowUpNotificationPatternRegistrableToJSON(
          requestParameters.pharmacyFollowUpNotificationPatternRegistrable
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyFollowUpNotificationPatternFromJSON(jsonValue));
  }

  /**
   * Register chain follow up notification patterns
   */
  async postChainFollowUpNotificationPattern(
    requestParameters: PostChainFollowUpNotificationPatternRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyFollowUpNotificationPattern> {
    return promiseRetry(
      (retry) =>
        this.postChainFollowUpNotificationPatternRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register chain follow up questionnaire patterns
   */
  async postChainFollowUpQuestionnairePatternRaw(
    requestParameters: PostChainFollowUpQuestionnairePatternRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyFollowUpQuestionnairePattern>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling postChainFollowUpQuestionnairePattern."
      );
    }

    if (
      requestParameters.pharmacyFollowUpQuestionnairePatternRegistrable === null ||
      requestParameters.pharmacyFollowUpQuestionnairePatternRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyFollowUpQuestionnairePatternRegistrable",
        "Required parameter requestParameters.pharmacyFollowUpQuestionnairePatternRegistrable was null or undefined when calling postChainFollowUpQuestionnairePattern."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}/follow-up-questionnaire-patterns`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyFollowUpQuestionnairePatternRegistrableToJSON(
          requestParameters.pharmacyFollowUpQuestionnairePatternRegistrable
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PharmacyFollowUpQuestionnairePatternFromJSON(jsonValue)
    );
  }

  /**
   * Register chain follow up questionnaire patterns
   */
  async postChainFollowUpQuestionnairePattern(
    requestParameters: PostChainFollowUpQuestionnairePatternRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyFollowUpQuestionnairePattern> {
    return promiseRetry(
      (retry) =>
        this.postChainFollowUpQuestionnairePatternRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Put chain\'s pharmacy recurring calendars
   */
  async putChainPharmacyRecurringCalendarsCsvRaw(
    requestParameters: PutChainPharmacyRecurringCalendarsCsvRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putChainPharmacyRecurringCalendarsCsv."
      );
    }

    if (requestParameters.body === null || requestParameters.body === undefined) {
      throw new runtime.RequiredError(
        "body",
        "Required parameter requestParameters.body was null or undefined when calling putChainPharmacyRecurringCalendarsCsv."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "text/csv";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}/pharmacy-recurring-calendars.csv`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.body as any,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Put chain\'s pharmacy recurring calendars
   */
  async putChainPharmacyRecurringCalendarsCsv(
    requestParameters: PutChainPharmacyRecurringCalendarsCsvRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.putChainPharmacyRecurringCalendarsCsvRaw(requestParameters, initOverrides);
  }

  /**
   * Update bookmarks
   */
  async updateBookmarksRaw(
    requestParameters: UpdateBookmarksRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyBookmark>>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling updateBookmarks."
      );
    }

    if (
      requestParameters.patchBookmarksRequestBody === null ||
      requestParameters.patchBookmarksRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "patchBookmarksRequestBody",
        "Required parameter requestParameters.patchBookmarksRequestBody was null or undefined when calling updateBookmarks."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}/bookmarks`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchBookmarksRequestBodyToJSON(requestParameters.patchBookmarksRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyBookmarkFromJSON));
  }

  /**
   * Update bookmarks
   */
  async updateBookmarks(
    requestParameters: UpdateBookmarksRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyBookmark>> {
    return promiseRetry(
      (retry) =>
        this.updateBookmarksRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update chain
   */
  async updateChainRaw(
    requestParameters: UpdateChainRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyChain>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling updateChain."
      );
    }

    if (requestParameters.pharmacyChainUpdatable === null || requestParameters.pharmacyChainUpdatable === undefined) {
      throw new runtime.RequiredError(
        "pharmacyChainUpdatable",
        "Required parameter requestParameters.pharmacyChainUpdatable was null or undefined when calling updateChain."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}`.replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyChainUpdatableToJSON(requestParameters.pharmacyChainUpdatable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyChainFromJSON(jsonValue));
  }

  /**
   * Update chain
   */
  async updateChain(requestParameters: UpdateChainRequest, initOverrides?: RequestInit): Promise<PharmacyChain> {
    return promiseRetry(
      (retry) =>
        this.updateChainRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update chain follow up notification patterns
   */
  async updateChainFollowUpNotificationPatternRaw(
    requestParameters: UpdateChainFollowUpNotificationPatternRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyFollowUpNotificationPattern>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling updateChainFollowUpNotificationPattern."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateChainFollowUpNotificationPattern."
      );
    }

    if (
      requestParameters.pharmacyFollowUpNotificationPatternUpdatable === null ||
      requestParameters.pharmacyFollowUpNotificationPatternUpdatable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyFollowUpNotificationPatternUpdatable",
        "Required parameter requestParameters.pharmacyFollowUpNotificationPatternUpdatable was null or undefined when calling updateChainFollowUpNotificationPattern."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}/follow-up-notification-patterns/{id}`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyFollowUpNotificationPatternUpdatableToJSON(
          requestParameters.pharmacyFollowUpNotificationPatternUpdatable
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyFollowUpNotificationPatternFromJSON(jsonValue));
  }

  /**
   * Update chain follow up notification patterns
   */
  async updateChainFollowUpNotificationPattern(
    requestParameters: UpdateChainFollowUpNotificationPatternRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyFollowUpNotificationPattern> {
    return promiseRetry(
      (retry) =>
        this.updateChainFollowUpNotificationPatternRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update chain follow up questionnaire patterns
   */
  async updateChainFollowUpQuestionnairePatternRaw(
    requestParameters: UpdateChainFollowUpQuestionnairePatternRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyFollowUpQuestionnairePattern>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling updateChainFollowUpQuestionnairePattern."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateChainFollowUpQuestionnairePattern."
      );
    }

    if (
      requestParameters.pharmacyFollowUpQuestionnairePatternUpdatable === null ||
      requestParameters.pharmacyFollowUpQuestionnairePatternUpdatable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyFollowUpQuestionnairePatternUpdatable",
        "Required parameter requestParameters.pharmacyFollowUpQuestionnairePatternUpdatable was null or undefined when calling updateChainFollowUpQuestionnairePattern."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}/follow-up-questionnaire-patterns/{id}`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyFollowUpQuestionnairePatternUpdatableToJSON(
          requestParameters.pharmacyFollowUpQuestionnairePatternUpdatable
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PharmacyFollowUpQuestionnairePatternFromJSON(jsonValue)
    );
  }

  /**
   * Update chain follow up questionnaire patterns
   */
  async updateChainFollowUpQuestionnairePattern(
    requestParameters: UpdateChainFollowUpQuestionnairePatternRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyFollowUpQuestionnairePattern> {
    return promiseRetry(
      (retry) =>
        this.updateChainFollowUpQuestionnairePatternRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetPharmacyCountsVisibilityEnum {
  Public = "public",
  Private = "private",
  Limited = "limited",
}
