import { GatsbyLink, GatsbyLinkProps } from "@mgdx-libs/link";
import clsx from "clsx";
import React from "react";

import * as styles from "./PlainLink.module.css";

export type PlainLinkProps = Omit<GatsbyLinkProps<EmptyObject>, "ref"> & {
  widthType?: "over-container" | "full-width" | "fit-content";
  align?: "center" | "left" | "right";
  noPadding?: boolean;
  disabled?: boolean;
  iconPosition?: "top" | "center";
};

export const PlainLink: React.FC<PlainLinkProps> = ({
  className,
  widthType = "fit-content",
  align = "center",
  noPadding,
  iconPosition = "center",
  disabled,
  ...props
}) => {
  return (
    <GatsbyLink
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : undefined}
      className={clsx(
        styles.root,
        widthType === "over-container" && styles.overContainer,
        widthType === "full-width" && styles.fullWidth,
        noPadding && styles.noPadding,
        align === "center" && styles.textCenter,
        align === "left" && styles.textLeft,
        align === "right" && styles.textRight,
        iconPosition === "top" && styles.iconTop,
        iconPosition === "center" && styles.iconCenter,
        className
      )}
      {...props}
    />
  );
};
