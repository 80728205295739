import Container from "@mgdx/ui/components/Container";
import { FilledButton } from "@mgdx/ui/components/FilledButton";
import { MessagePlain } from "@mgdx/ui/components/MessagePlain";
import { PlainLinkButton } from "@mgdx/ui/components/PlainLink";
import Title from "@mgdx/ui/components/Title";
import React, { FC } from "react";

import { TextInnerLinkExternal } from "../../ui/TextInnerLinkExternal";
import * as styles from "./AgreementNewTermsReasonView.module.css";

export type AgreementNewTermsReasonViewProps = {
  isLoading?: boolean;
  onClickAgreement: VoidFunction;
  onClickShowWithdraw: VoidFunction;
};

export const AgreementNewTermsReasonView: FC<AgreementNewTermsReasonViewProps> = ({
  isLoading,
  onClickAgreement,
  onClickShowWithdraw,
}) => {
  return (
    <Container className={styles.container}>
      <div className={styles.content}>
        <Title variant="strong_left" className="mb-2 !pt-0">
          薬急便の利用規約変更について
        </Title>

        <MessagePlain variant="info">2024年11月5日(火曜)に主に以下の理由で利用規約を変更いたしました。</MessagePlain>

        <Title variant="normal">変更理由</Title>

        <MessagePlain variant="info">利用者様からお預かりする個人情報の提供先・提供目的の一部拡大</MessagePlain>

        <MessagePlain variant="info">
          詳しくは、
          <TextInnerLinkExternal
            href="https://help.yqb.jp/hc/ja/articles/10922429689871-利用規約の変更予告と変更概要のお知らせ"
            title="薬急便利用規約の変更詳細を開く"
          >
            薬急便利用規約の変更詳細
          </TextInnerLinkExternal>
          をご確認ください。
        </MessagePlain>

        <MessagePlain variant="info">
          利用者様がより便利にサービスをお使いいただけるようご理解のほどお願い申し上げます。なお利用者様からお預かりする個人情報は、当社の
          <TextInnerLinkExternal href="https://www.mg-dx.co.jp/privacy-policy/" title="プライバシーポリシーを開く">
            プライバシーポリシー
          </TextInnerLinkExternal>
          に基づき適切に管理いたします。
        </MessagePlain>

        <MessagePlain variant="info">
          利用規約の変更にご同意いただける場合は、「同意して利用する」を押してサービスにお進みください。
        </MessagePlain>
      </div>

      <Container className={styles.bottom}>
        <FilledButton
          data-testid="agreement-button"
          loading={isLoading}
          onClick={onClickAgreement}
          type="normal"
          variant="primary"
          widthType="full-width"
        >
          同意して利用する
        </FilledButton>

        <PlainLinkButton data-testid="show-withdraw-button" onClick={onClickShowWithdraw} widthType="full-width">
          退会する
        </PlainLinkButton>
      </Container>
    </Container>
  );
};
