import { logger } from "@mgdx-libs/logger";
import { IdTokenResult, User } from "firebase/auth";

export class FirebaseUserRoleNoneError extends Error {}

export const getAuthedFirebaseUserToken = async (firebaseUser: User): Promise<IdTokenResult> => {
  logger.debug("firebase.User: %o", firebaseUser);

  const result = await firebaseUser.getIdTokenResult().then((value) => {
    if (value.claims.role === undefined || value.claims.role === "none") {
      throw new FirebaseUserRoleNoneError("firebase.User: role is none");
    } else if (value.claims.role !== "patient") {
      throw new Error(`firebase.User: different role, ${value.claims.role}`);
    }
    return value;
  });

  logger.debug("getIdTokenResult: %o", result);
  return result;
};
