import clsx from "clsx";
import React, { ReactNode } from "react";

import { Variant } from "./Message";
import * as styles from "./Message.module.css";

export type MarkerListMessageProps = {
  className?: string;
  variant: Variant;
  withBackground?: boolean;
  list?: ReactNode[];
};

/**
 * @deprecated use ItemizedList instead
 */
export const MarkerListMessage = ({ className, variant, withBackground, list = [] }: MarkerListMessageProps) => {
  if (list.length === 0) return null;

  return (
    <div
      className={clsx(
        variant === "weak" && styles.weak,
        variant === "info" && styles.info,
        variant === "notice" && styles.notice,
        withBackground && styles.withBackground,
        styles.multiline,
        className
      )}
    >
      <ul className={clsx(styles.markerList, styles.message)}>
        {list.map((item, i) => (
          <li key={i} className={clsx(i !== 0 && "mt-2")}>
            <span className={styles.listMessage}>{item || <>&nbsp;</>}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
