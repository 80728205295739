import patientApi from "@mgdx/api/lib/patientApi";
import { Patient } from "@mgdx/shared/src/models/Patient";
import { errorHandlerReport } from "@mgdx-libs/error-handler";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { setCurrentUserWithDispatch } from "../../ducks/currentUser";
import { patientKeys } from "../../factories";

type ApiResponseData = Patient;
type ApiError = Error;
type ApiRequestVariables = void;

type MutationOptions = Omit<
  UseMutationOptions<ApiResponseData, ApiError, ApiRequestVariables>,
  "mutationFn" | "onSuccess" | "onError"
>;

export const useUpdateAgreementMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation<ApiResponseData, ApiError, ApiRequestVariables>({
    mutationFn: (_: ApiRequestVariables) =>
      patientApi.putAgreement({ putPatientAgreementRequestBody: { agreement: true } }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: patientKeys.patient.current.queryKey,
      });
      setCurrentUserWithDispatch(dispatch, data);
    },
    onError: (error) => {
      errorHandlerReport(error);
    },
    ...options,
  });
};
