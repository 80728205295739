/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PatientCustomBadRequest9,
  PatientCustomBadRequest9FromJSON,
  PatientCustomBadRequest9ToJSON,
  PatientPatientReceiptAssociation,
  PatientPatientReceiptAssociationFromJSON,
  PatientPatientReceiptAssociationToJSON,
  PatientPatientReceiptAssociations,
  PatientPatientReceiptAssociationsFromJSON,
  PatientPatientReceiptAssociationsToJSON,
  PostPatientReceiptAssociationRequestBody,
  PostPatientReceiptAssociationRequestBodyFromJSON,
  PostPatientReceiptAssociationRequestBodyToJSON,
} from "../models";

export interface DeletePatientReceiptAssociationsRequest {
  patientReceiptAssociationId: string;
}

export interface GetPatientReceiptAssociationsRequest {
  patientIds: Array<number>;
  pharmacyId: number;
  offset?: number;
  limit?: number;
}

export interface PostPatientReceiptAssociationsRequest {
  postPatientReceiptAssociationRequestBody: PostPatientReceiptAssociationRequestBody;
}

/**
 *
 */
export class PatientReceiptAssociationApi extends runtime.BaseAPI {
  /**
   * Delete patient receipt associations
   */
  async deletePatientReceiptAssociationsRaw(
    requestParameters: DeletePatientReceiptAssociationsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.patientReceiptAssociationId === null ||
      requestParameters.patientReceiptAssociationId === undefined
    ) {
      throw new runtime.RequiredError(
        "patientReceiptAssociationId",
        "Required parameter requestParameters.patientReceiptAssociationId was null or undefined when calling deletePatientReceiptAssociations."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient-receipt-associations/{patient_receipt_association_id}`.replace(
          `{${"patient_receipt_association_id"}}`,
          encodeURIComponent(String(requestParameters.patientReceiptAssociationId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete patient receipt associations
   */
  async deletePatientReceiptAssociations(
    requestParameters: DeletePatientReceiptAssociationsRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deletePatientReceiptAssociationsRaw(requestParameters, initOverrides);
  }

  /**
   * Get patient receipt associations
   */
  async getPatientReceiptAssociationsRaw(
    requestParameters: GetPatientReceiptAssociationsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientPatientReceiptAssociations>> {
    if (requestParameters.patientIds === null || requestParameters.patientIds === undefined) {
      throw new runtime.RequiredError(
        "patientIds",
        "Required parameter requestParameters.patientIds was null or undefined when calling getPatientReceiptAssociations."
      );
    }

    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling getPatientReceiptAssociations."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.patientIds) {
      queryParameters["patient_ids"] = requestParameters.patientIds;
    }

    if (requestParameters.pharmacyId !== undefined) {
      queryParameters["pharmacy_id"] = requestParameters.pharmacyId;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient-receipt-associations`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientPatientReceiptAssociationsFromJSON(jsonValue));
  }

  /**
   * Get patient receipt associations
   */
  async getPatientReceiptAssociations(
    requestParameters: GetPatientReceiptAssociationsRequest,
    initOverrides?: RequestInit
  ): Promise<PatientPatientReceiptAssociations> {
    return promiseRetry(
      (retry) =>
        this.getPatientReceiptAssociationsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register patient receipt associations
   */
  async postPatientReceiptAssociationsRaw(
    requestParameters: PostPatientReceiptAssociationsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientPatientReceiptAssociation>> {
    if (
      requestParameters.postPatientReceiptAssociationRequestBody === null ||
      requestParameters.postPatientReceiptAssociationRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postPatientReceiptAssociationRequestBody",
        "Required parameter requestParameters.postPatientReceiptAssociationRequestBody was null or undefined when calling postPatientReceiptAssociations."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient-receipt-associations`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostPatientReceiptAssociationRequestBodyToJSON(
          requestParameters.postPatientReceiptAssociationRequestBody
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientPatientReceiptAssociationFromJSON(jsonValue));
  }

  /**
   * Register patient receipt associations
   */
  async postPatientReceiptAssociations(
    requestParameters: PostPatientReceiptAssociationsRequest,
    initOverrides?: RequestInit
  ): Promise<PatientPatientReceiptAssociation> {
    return promiseRetry(
      (retry) =>
        this.postPatientReceiptAssociationsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
