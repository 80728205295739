/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientInterviewTypename = "PatientInterview" as const;

/**
 *
 * @export
 * @interface PatientInterview
 */
export interface PatientInterview {
  /**
   *
   * @type {Date}
   * @memberof PatientInterview
   */
  answeredTime?: string;
  /**
   *
   * @type {string}
   * @memberof PatientInterview
   */
  interviewsType: PatientInterviewInterviewsTypeEnum;
  /**
   *
   * @type {Date}
   * @memberof PatientInterview
   */
  lastConfirmedTime?: string;
  /**
   * Keys except key 'questionnaires' are for custom use only
   * @type {object}
   * @memberof PatientInterview
   */
  questionnaires: object;
  /**
   *
   * @type {number}
   * @memberof PatientInterview
   */
  version?: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientInterview
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientInterviewInterviewsTypeEnum {
  Default = "default",
  Custom = "custom",
  Unused = "unused",
}

export function PatientInterviewFromJSON(json: any): PatientInterview {
  return PatientInterviewFromJSONTyped(json, false);
}

export function PatientInterviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientInterview {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientInterviewTypename,

    answeredTime:
      !exists(json, "answered_time") ||
      (typeof json["answered_time"] === "string" && json["answered_time"].substring(0, 4) === "0001")
        ? undefined
        : json["answered_time"],
    interviewsType: json["interviews_type"],
    lastConfirmedTime:
      !exists(json, "last_confirmed_time") ||
      (typeof json["last_confirmed_time"] === "string" && json["last_confirmed_time"].substring(0, 4) === "0001")
        ? undefined
        : json["last_confirmed_time"],
    questionnaires: json["questionnaires"],
    version: !exists(json, "version") ? undefined : json["version"],
  };
}

export function PatientInterviewToJSON(object?: PatientInterview | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    answered_time: value.answeredTime === undefined ? undefined : moment(value.answeredTime).tz("Asia/Tokyo").format(),
    interviews_type: value.interviewsType,
    last_confirmed_time:
      value.lastConfirmedTime === undefined ? undefined : moment(value.lastConfirmedTime).tz("Asia/Tokyo").format(),
    questionnaires: value.questionnaires,
    version: value.version,
  };
}
