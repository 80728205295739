/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PharmacyInterviewCounselingType {
  OnlineCounseling = "online_counseling",
  Dispensing = "dispensing",
  DirectVisit = "direct_visit",
}

export function PharmacyInterviewCounselingTypeFromJSON(json: any): PharmacyInterviewCounselingType {
  return PharmacyInterviewCounselingTypeFromJSONTyped(json, false);
}

export function PharmacyInterviewCounselingTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyInterviewCounselingType {
  return json as PharmacyInterviewCounselingType;
}

export function PharmacyInterviewCounselingTypeToJSON(value?: PharmacyInterviewCounselingType | null): any {
  return value as any;
}
