// extracted by mini-css-extract-plugin
export var base = "BaseInput-module--base--236bf";
export var chip = "BaseInput-module--chip--d77b5";
export var completed = "BaseInput-module--completed--7e2b6";
export var container = "BaseInput-module--container--c8a10";
export var error = "BaseInput-module--error--a3265";
export var group = "BaseInput-module--group--5ed43";
export var iconContainer = "BaseInput-module--iconContainer--428ff";
export var label = "BaseInput-module--label--af848";
export var labelText = "BaseInput-module--labelText--801ed";
export var legend = "BaseInput-module--legend--b5e6a";
export var withFrame = "BaseInput-module--withFrame--2db8f";