import moment from "@mgdx-libs/moment";
import { useDispatch, useSelector } from "react-redux";

import { clearLiffParams, LiffState } from "../ducks/liff";
import { RootState } from "../ducks/root";

const SESSION_EXPIRE_MINITE = 5;

const useLiffParams = (): LiffState => {
  const dispatch = useDispatch();
  const org = useSelector<RootState, LiffState>(({ liff }) => liff);

  if (org.expiredTime) {
    const saveTime = moment(org.expiredTime);
    const current = moment();
    const expired = saveTime && current.diff(saveTime, "minute") > SESSION_EXPIRE_MINITE;
    if (expired) {
      dispatch(clearLiffParams());
      return {};
    }
  }

  return org;
};

export default useLiffParams;
