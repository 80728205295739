/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PutCounselingReserverTypeRequestBody,
  PutCounselingReserverTypeRequestBodyFromJSON,
  PutCounselingReserverTypeRequestBodyToJSON,
} from "../models";

export interface PutCounselingsReserverTypeRequest {
  counselingId: number;
  putCounselingReserverTypeRequestBody: PutCounselingReserverTypeRequestBody;
}

/**
 *
 */
export class ReserverTypeApi extends runtime.BaseAPI {
  /**
   * Change reserver-type
   */
  async putCounselingsReserverTypeRaw(
    requestParameters: PutCounselingsReserverTypeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling putCounselingsReserverType."
      );
    }

    if (
      requestParameters.putCounselingReserverTypeRequestBody === null ||
      requestParameters.putCounselingReserverTypeRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putCounselingReserverTypeRequestBody",
        "Required parameter requestParameters.putCounselingReserverTypeRequestBody was null or undefined when calling putCounselingsReserverType."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/reserver-type`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutCounselingReserverTypeRequestBodyToJSON(requestParameters.putCounselingReserverTypeRequestBody),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Change reserver-type
   */
  async putCounselingsReserverType(
    requestParameters: PutCounselingsReserverTypeRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.putCounselingsReserverTypeRaw(requestParameters, initOverrides);
  }
}
