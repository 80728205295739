import Container from "@mgdx/ui/components/Container";
import { PlainLinkButton } from "@mgdx/ui/components/PlainLink";
import React, { FC } from "react";

import { AccountDeleteView, Props as AccountDeleteViewProps } from "../AccountDeleteView";

export type AgreementNewTermsWithdrawViewProps = Pick<AccountDeleteViewProps, "deleteUser"> & {
  onClickShowReason: VoidFunction;
};

export const AgreementNewTermsWithdrawView: FC<AgreementNewTermsWithdrawViewProps> = ({
  deleteUser,
  onClickShowReason,
}) => {
  return (
    <>
      <AccountDeleteView deleteUser={deleteUser} />

      <Container>
        <PlainLinkButton
          data-testid="show-reason-button"
          onClick={onClickShowReason}
          widthType="full-width"
          className="mt-4"
        >
          前へ戻る
        </PlainLinkButton>
      </Container>
    </>
  );
};
