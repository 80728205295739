import ModalDialog from "@mgdx/ui/components/ModalDialog";
import React, { FC } from "react";

import {
  AgreementNewTermsConfirmView,
  AgreementNewTermsConfirmViewProps,
} from "../../views/AgreementNewTermsConfirmView";

export const AgreementNewTermsConfirmDialog: FC<AgreementNewTermsConfirmViewProps> = ({
  isLoading,
  onClickAgreement,
  onClickShowReason,
}) => {
  return (
    <ModalDialog isOpen={true} variant="confirm">
      <AgreementNewTermsConfirmView
        isLoading={isLoading}
        onClickAgreement={onClickAgreement}
        onClickShowReason={onClickShowReason}
      />
    </ModalDialog>
  );
};
