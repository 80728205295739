import clsx from "clsx";
import React, { ButtonHTMLAttributes } from "react";

import * as styles from "./TextInnerLink.module.css";

export type TextInnerButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const TextInnerButton: React.FC<TextInnerButtonProps> = ({ className, children, ...props }) => (
  <button type="button" className={clsx(styles.container, className)} {...props}>
    {children}
  </button>
);
