import clsx from "clsx";
import React, { PropsWithChildren } from "react";

import * as styles from "./Message.module.css";

export const variants = [
  "weak",
  "weak-center",
  "notice",
  "notice-center",
  "info",
  "info-center",
  "caution",
  "strong",
  "strong-left",
  "responsive",
] as const;
export type Variant = (typeof variants)[number];

export type Props = PropsWithChildren<{
  className?: string;
  variant: Variant;
  multiline?: boolean;
  form?: boolean;
}>;

/**
 * @deprecated use MessagePlain instead
 */
const Message = ({ children, variant, multiline = false, form = false, className }: Props) => (
  <div
    className={clsx(
      styles.root,
      variant === "weak" && styles.weak,
      variant === "weak-center" && styles.weakCenter,
      variant === "notice" && styles.notice,
      variant === "notice-center" && styles.noticeCenter,
      variant === "info" && styles.info,
      variant === "info-center" && styles.infoCenter,
      variant === "caution" && styles.caution,
      variant === "strong" && styles.strong,
      variant === "strong-left" && styles.strongLeft,
      variant === "responsive" && styles.responsive,
      multiline && styles.multiline,
      form && styles.form,
      className
    )}
  >
    <div className={styles.message}>{children}</div>
  </div>
);

export default Message;
