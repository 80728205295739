/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientReceiptPatientTypename = "PatientReceiptPatient" as const;

/**
 *
 * @export
 * @interface PatientReceiptPatient
 */
export interface PatientReceiptPatient {
  /**
   *
   * @type {number}
   * @memberof PatientReceiptPatient
   */
  chainId: number;
  /**
   *
   * @type {string}
   * @memberof PatientReceiptPatient
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof PatientReceiptPatient
   */
  gender: PatientReceiptPatientGenderEnum;
  /**
   *
   * @type {string}
   * @memberof PatientReceiptPatient
   */
  insuredCardCode?: string;
  /**
   *
   * @type {string}
   * @memberof PatientReceiptPatient
   */
  insuredCardNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PatientReceiptPatient
   */
  insurerNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PatientReceiptPatient
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof PatientReceiptPatient
   */
  pharmacyId: number;
  /**
   *
   * @type {string}
   * @memberof PatientReceiptPatient
   */
  receiptCode: string;
  /**
   *
   * @type {string}
   * @memberof PatientReceiptPatient
   */
  receiptPatientId: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientReceiptPatient
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientReceiptPatientGenderEnum {
  Male = "male",
  Female = "female",
  None = "none",
}

export function PatientReceiptPatientFromJSON(json: any): PatientReceiptPatient {
  return PatientReceiptPatientFromJSONTyped(json, false);
}

export function PatientReceiptPatientFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientReceiptPatient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientReceiptPatientTypename,

    chainId: json["chain_id"],
    dateOfBirth: !exists(json, "date_of_birth") ? undefined : json["date_of_birth"],
    gender: json["gender"],
    insuredCardCode: !exists(json, "insured_card_code") ? undefined : json["insured_card_code"],
    insuredCardNumber: !exists(json, "insured_card_number") ? undefined : json["insured_card_number"],
    insurerNumber: !exists(json, "insurer_number") ? undefined : json["insurer_number"],
    name: !exists(json, "name") ? undefined : json["name"],
    pharmacyId: json["pharmacy_id"],
    receiptCode: json["receipt_code"],
    receiptPatientId: json["receipt_patient_id"],
  };
}

export function PatientReceiptPatientToJSON(object?: PatientReceiptPatient | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    chain_id: value.chainId,
    date_of_birth: value.dateOfBirth,
    gender: value.gender,
    insured_card_code: value.insuredCardCode,
    insured_card_number: value.insuredCardNumber,
    insurer_number: value.insurerNumber,
    name: value.name,
    pharmacy_id: value.pharmacyId,
    receipt_code: value.receiptCode,
    receipt_patient_id: value.receiptPatientId,
  };
}
