import { disableTalk } from "@mgdx/talk/ducks";
import { clearAccessToken } from "@mgdx-libs/firebase";
import { abortRequests, clearRequestsCache, resetRequests } from "@redux-requests/core";
import { Dispatch } from "redux";

import { clearCurrentUser } from "../../ducks/currentUser";

export const clearTokenAndState = (dispatch: Dispatch) => {
  dispatch(disableTalk());
  dispatch(abortRequests());
  dispatch(clearRequestsCache());
  dispatch(resetRequests());
  dispatch(clearCurrentUser());
  clearAccessToken();
};
