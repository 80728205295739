/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientMedicineAlarmSettingStatus,
  PatientMedicineAlarmSettingStatusFromJSON,
  PatientMedicineAlarmSettingStatusFromJSONTyped,
  PatientMedicineAlarmSettingStatusToJSON,
  PatientMedicineAlarmTiming,
  PatientMedicineAlarmTimingFromJSON,
  PatientMedicineAlarmTimingFromJSONTyped,
  PatientMedicineAlarmTimingToJSON,
} from "./";

export const PatientMedicineAlarmSettingRegistrableTypename = "PatientMedicineAlarmSettingRegistrable" as const;

/**
 *
 * @export
 * @interface PatientMedicineAlarmSettingRegistrable
 */
export interface PatientMedicineAlarmSettingRegistrable {
  /**
   *
   * @type {string}
   * @memberof PatientMedicineAlarmSettingRegistrable
   */
  endDate?: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineAlarmSettingRegistrable
   */
  requestType: PatientMedicineAlarmSettingRegistrableRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineAlarmSettingRegistrable
   */
  startDate: string;
  /**
   *
   * @type {PatientMedicineAlarmSettingStatus}
   * @memberof PatientMedicineAlarmSettingRegistrable
   */
  status?: PatientMedicineAlarmSettingStatus;
  /**
   *
   * @type {Array<PatientMedicineAlarmTiming>}
   * @memberof PatientMedicineAlarmSettingRegistrable
   */
  timingsOfTheDay: Array<PatientMedicineAlarmTiming>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMedicineAlarmSettingRegistrable
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientMedicineAlarmSettingRegistrableRequestTypeEnum {
  Web = "web",
  Line = "line",
}

export function PatientMedicineAlarmSettingRegistrableFromJSON(json: any): PatientMedicineAlarmSettingRegistrable {
  return PatientMedicineAlarmSettingRegistrableFromJSONTyped(json, false);
}

export function PatientMedicineAlarmSettingRegistrableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientMedicineAlarmSettingRegistrable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMedicineAlarmSettingRegistrableTypename,

    endDate: !exists(json, "end_date") ? undefined : json["end_date"],
    requestType: json["request_type"],
    startDate: json["start_date"],
    status: !exists(json, "status") ? undefined : PatientMedicineAlarmSettingStatusFromJSON(json["status"]),
    timingsOfTheDay: (json["timings_of_the_day"] as Array<any>).map(PatientMedicineAlarmTimingFromJSON),
  };
}

export function PatientMedicineAlarmSettingRegistrableToJSON(
  object?: PatientMedicineAlarmSettingRegistrable | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    end_date: value.endDate,
    request_type: value.requestType,
    start_date: value.startDate,
    status: PatientMedicineAlarmSettingStatusToJSON(value.status),
    timings_of_the_day: (value.timingsOfTheDay as Array<any>).map(PatientMedicineAlarmTimingToJSON),
  };
}
