import React from "react";

import * as styles from "./ErrorForMccmApp.module.css";

export type ErrorForMccmAppProps = EmptyObject;

export const ErrorForMccmApp: React.FC<ErrorForMccmAppProps> = () => (
  <div className={styles.container}>
    <div className={styles.layout}>
      <div className="mb-11">
        <div className={styles.content}>
          <img src="/images/icon_error.svg" alt="" width="120" height="120" decoding="async" />
          <div className="mt-6">
            <h1 className={styles.title}>エラーが発生しました</h1>
          </div>
          <div className="mt-3 text-center">
            <p className={styles.paragraph}>
              {"恐れ入りますが、\nしばらく時間を置いて\n再度ログイン操作をお願いします。"}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);
