import clsx from "clsx";
import React from "react";

import CircularProgress from "../CircularProgress";
import * as styles from "./PlainLink.module.css";

export type PlainLinkButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type"> & {
  widthType?: "over-container" | "full-width" | "fit-content";
  align?: "center" | "left" | "right";
  noPadding?: boolean;
  iconPosition?: "top" | "center";
  loading?: boolean;
};

export const PlainLinkButton: React.FC<PlainLinkButtonProps> = ({
  className,
  widthType = "fit-content",
  align = "center",
  noPadding,
  iconPosition = "center",
  loading,
  children,
  ...props
}) => {
  return (
    <button
      type="button"
      className={clsx(
        styles.root,
        widthType === "over-container" && styles.overContainer,
        widthType === "full-width" && styles.fullWidth,
        noPadding && styles.noPadding,
        align === "center" && styles.textCenter,
        align === "left" && styles.textLeft,
        align === "right" && styles.textRight,
        iconPosition === "top" && styles.iconTop,
        iconPosition === "center" && styles.iconCenter,
        className
      )}
      {...props}
    >
      {loading ? <CircularProgress /> : children}
    </button>
  );
};
