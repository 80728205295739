import clsx from "clsx";
import React from "react";

import * as styles from "./Container.module.css";

export const layouts = ["responsive", "tablet", "mainBoard"] as const;

export type Props = React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> & {
  layout?: (typeof layouts)[number];
  noPadding?: boolean;
};

const Container = ({ className, noPadding, layout, ...props }: Props) => {
  return (
    <div
      {...props}
      className={clsx(
        styles.container,
        noPadding && styles.noPadding,
        layout === "tablet" && styles.tablet,
        layout === "responsive" && styles.responsive,
        layout === "mainBoard" && styles.mainBoard,
        className
      )}
    />
  );
};

export default Container;
