/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PostPatientSyncEMSRequestBodyTypename = "PostPatientSyncEMSRequestBody" as const;

/**
 *
 * @export
 * @interface PostPatientSyncEMSRequestBody
 */
export interface PostPatientSyncEMSRequestBody {
  /**
   *
   * @type {string}
   * @memberof PostPatientSyncEMSRequestBody
   */
  dateOfBirth: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostPatientSyncEMSRequestBody
   */
  readonly __typename?: string;
}

export function PostPatientSyncEMSRequestBodyFromJSON(json: any): PostPatientSyncEMSRequestBody {
  return PostPatientSyncEMSRequestBodyFromJSONTyped(json, false);
}

export function PostPatientSyncEMSRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostPatientSyncEMSRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostPatientSyncEMSRequestBodyTypename,

    dateOfBirth: json["date_of_birth"],
  };
}

export function PostPatientSyncEMSRequestBodyToJSON(object?: PostPatientSyncEMSRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date_of_birth: value.dateOfBirth,
  };
}
