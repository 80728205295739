/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientPatientNotifyTypeTypename = "PatientPatientNotifyType" as const;

/**
 *
 * @export
 * @interface PatientPatientNotifyType
 */
export interface PatientPatientNotifyType {
  /**
   *
   * @type {boolean}
   * @memberof PatientPatientNotifyType
   */
  app?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatientPatientNotifyType
   */
  email?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatientPatientNotifyType
   */
  lineOfficialAccount?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatientPatientNotifyType
   */
  lineServiceMessage?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatientPatientNotifyType
   */
  sms?: boolean;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientPatientNotifyType
   */
  readonly __typename?: string;
}

export function PatientPatientNotifyTypeFromJSON(json: any): PatientPatientNotifyType {
  return PatientPatientNotifyTypeFromJSONTyped(json, false);
}

export function PatientPatientNotifyTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientPatientNotifyType {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientPatientNotifyTypeTypename,

    app: !exists(json, "app") ? undefined : json["app"],
    email: !exists(json, "email") ? undefined : json["email"],
    lineOfficialAccount: !exists(json, "line_official_account") ? undefined : json["line_official_account"],
    lineServiceMessage: !exists(json, "line_service_message") ? undefined : json["line_service_message"],
    sms: !exists(json, "sms") ? undefined : json["sms"],
  };
}

export function PatientPatientNotifyTypeToJSON(object?: PatientPatientNotifyType | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    app: value.app,
    email: value.email,
    line_official_account: value.lineOfficialAccount,
    line_service_message: value.lineServiceMessage,
    sms: value.sms,
  };
}
