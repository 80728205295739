/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientReceiptPatient,
  PatientReceiptPatientFromJSON,
  PatientReceiptPatientFromJSONTyped,
  PatientReceiptPatientToJSON,
} from "./";

export const PatientPatientReceiptAssociationTypename = "PatientPatientReceiptAssociation" as const;

/**
 *
 * @export
 * @interface PatientPatientReceiptAssociation
 */
export interface PatientPatientReceiptAssociation {
  /**
   *
   * @type {string}
   * @memberof PatientPatientReceiptAssociation
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof PatientPatientReceiptAssociation
   */
  patientId: number;
  /**
   *
   * @type {PatientReceiptPatient}
   * @memberof PatientPatientReceiptAssociation
   */
  receiptPatient: PatientReceiptPatient;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientPatientReceiptAssociation
   */
  readonly __typename?: string;
}

export function PatientPatientReceiptAssociationFromJSON(json: any): PatientPatientReceiptAssociation {
  return PatientPatientReceiptAssociationFromJSONTyped(json, false);
}

export function PatientPatientReceiptAssociationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientPatientReceiptAssociation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientPatientReceiptAssociationTypename,

    id: json["id"],
    patientId: json["patient_id"],
    receiptPatient: PatientReceiptPatientFromJSON(json["receipt_patient"]),
  };
}

export function PatientPatientReceiptAssociationToJSON(object?: PatientPatientReceiptAssociation | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    patient_id: value.patientId,
    receipt_patient: PatientReceiptPatientToJSON(value.receiptPatient),
  };
}
