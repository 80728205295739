import CheckOvalIcon from "@mgdx/assets/icons/CheckOvalIcon";
import clsx from "clsx";
import React, { forwardRef, PropsWithChildren } from "react";

import Chip from "../../Chip";
import * as styles from "./BaseInput.module.css";

export type BaseInputProps = PropsWithChildren<{
  label: React.ReactNode;
  htmlFor?: string;
  noFrame?: boolean;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
  completed?: boolean;
  isGroup?: boolean;
  className?: string;
  inputContainerClassName?: string;
}>;

export const BaseInput = forwardRef<HTMLFieldSetElement, BaseInputProps>(
  (
    {
      htmlFor,
      label,
      noFrame,
      required,
      error,
      disabled,
      completed,
      isGroup,
      className,
      children,
      inputContainerClassName,
      ...props
    },
    ref
  ) => (
    <fieldset
      ref={ref}
      disabled={disabled}
      className={clsx(
        styles.base,
        !noFrame && styles.withFrame,
        error && styles.error,
        completed && styles.completed,
        isGroup && styles.group,
        className
      )}
      {...props}
    >
      <legend className={styles.legend}>
        <label className={clsx(completed && styles.label)} htmlFor={htmlFor}>
          <span className={styles.labelText}>{label}</span>
          {completed && <CheckOvalIcon size="s" variant="secondary" className={styles.chip} />}
          {!completed && required === true && (
            <Chip variant="caution" className={styles.chip}>
              必須
            </Chip>
          )}
          {!completed && required === false && (
            <Chip variant="weak" className={styles.chip}>
              任意
            </Chip>
          )}
        </label>
      </legend>
      <div className={clsx(styles.container, inputContainerClassName)}>{children}</div>
    </fieldset>
  )
);
