import clsx from "clsx";
import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby-link";
import React from "react";

import * as styles from "./TextInnerLink.module.css";

export type TextInnerLinkProps = Omit<GatsbyLinkProps<EmptyObject>, "ref"> & {
  disabled?: boolean;
};

export const TextInnerLink: React.FC<TextInnerLinkProps> = ({ to, disabled, className, children, ...props }) => (
  <GatsbyLink to={to} className={clsx(styles.container, disabled && styles.disabled, className)} {...props}>
    {children}
  </GatsbyLink>
);
