import clsx from "clsx";
import React, { LiHTMLAttributes, ReactNode } from "react";

import { Variant } from "./Message";
import * as styles from "./Message.module.css";

export type ListMessageProps = Pick<LiHTMLAttributes<HTMLLIElement>, "aria-live"> & {
  className?: string;
  variant: Variant;
  list?: ReactNode[];
};

/**
 * @deprecated use MessagePlain instead
 */
export const ListMessage = ({ className, variant, list = [], "aria-live": ariaLive }: ListMessageProps) => {
  if (list.length === 0) return null;

  return (
    <div
      className={clsx(
        styles.root,
        variant === "weak" && styles.weak,
        variant === "info" && styles.info,
        variant === "notice" && styles.notice,
        variant === "caution" && styles.caution,
        variant === "strong" && styles.strong,
        variant === "strong-left" && styles.strongLeft,
        variant === "responsive" && styles.responsive,
        styles.multiline,

        className
      )}
    >
      <ul className={clsx(styles.list, styles.message)}>
        {list.map((item, i) => (
          <li key={i} aria-live={ariaLive}>
            {item || <>&nbsp;</>}
          </li>
        ))}
      </ul>
    </div>
  );
};
