/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX bff-patient API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const BffPatientMCCMJAHISSyncJobTypename = "BffPatientMCCMJAHISSyncJob" as const;

/**
 *
 * @export
 * @interface BffPatientMCCMJAHISSyncJob
 */
export interface BffPatientMCCMJAHISSyncJob {
  /**
   *
   * @type {number}
   * @memberof BffPatientMCCMJAHISSyncJob
   */
  chainId: number;
  /**
   *
   * @type {number}
   * @memberof BffPatientMCCMJAHISSyncJob
   */
  familyAccountId?: number;
  /**
   *
   * @type {string}
   * @memberof BffPatientMCCMJAHISSyncJob
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof BffPatientMCCMJAHISSyncJob
   */
  patientId: number;
  /**
   *
   * @type {Date}
   * @memberof BffPatientMCCMJAHISSyncJob
   */
  registeredTime: string;
  /**
   *
   * @type {string}
   * @memberof BffPatientMCCMJAHISSyncJob
   */
  status: BffPatientMCCMJAHISSyncJobStatusEnum;
  /**
   *
   * @type {Date}
   * @memberof BffPatientMCCMJAHISSyncJob
   */
  syncedTime?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof BffPatientMCCMJAHISSyncJob
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum BffPatientMCCMJAHISSyncJobStatusEnum {
  Initialized = "initialized",
  Processing = "processing",
  Synced = "synced",
  Failed = "failed",
}

export function BffPatientMCCMJAHISSyncJobFromJSON(json: any): BffPatientMCCMJAHISSyncJob {
  return BffPatientMCCMJAHISSyncJobFromJSONTyped(json, false);
}

export function BffPatientMCCMJAHISSyncJobFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BffPatientMCCMJAHISSyncJob {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: BffPatientMCCMJAHISSyncJobTypename,

    chainId: json["chain_id"],
    familyAccountId: !exists(json, "family_account_id") ? undefined : json["family_account_id"],
    id: json["id"],
    patientId: json["patient_id"],
    registeredTime: json["registered_time"],
    status: json["status"],
    syncedTime:
      !exists(json, "synced_time") ||
      (typeof json["synced_time"] === "string" && json["synced_time"].substring(0, 4) === "0001")
        ? undefined
        : json["synced_time"],
  };
}

export function BffPatientMCCMJAHISSyncJobToJSON(object?: BffPatientMCCMJAHISSyncJob | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    chain_id: value.chainId,
    family_account_id: value.familyAccountId,
    id: value.id,
    patient_id: value.patientId,
    registered_time: moment(value.registeredTime).tz("Asia/Tokyo").format(),
    status: value.status,
    synced_time: value.syncedTime === undefined ? undefined : moment(value.syncedTime).tz("Asia/Tokyo").format(),
  };
}
