// extracted by mini-css-extract-plugin
export var checkbox = "InputCheckbox-module--checkbox--f6cbc";
export var checked = "InputCheckbox-module--checked--ac5d7";
export var checkmark = "InputCheckbox-module--checkmark--92976";
export var completed = "InputCheckbox-module--completed--4df05";
export var container = "InputCheckbox-module--container--400c0";
export var disabled = "InputCheckbox-module--disabled--1bb76";
export var indicator = "InputCheckbox-module--indicator--18641";
export var input = "InputCheckbox-module--input--9580a";
export var label = "InputCheckbox-module--label--6cce2";
export var nowrap = "InputCheckbox-module--nowrap--01465";
export var widePadding = "InputCheckbox-module--widePadding--0b02b";