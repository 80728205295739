import { getFirebaseUser } from "@mgdx-libs/firebase";
import { useMemo } from "react";

import { OIDCProvider } from "./OIDCProvider";

type UseIsOIDCCredential = (args: { providerId: OIDCProvider }) => boolean;

const useIsOIDCCredential: UseIsOIDCCredential = ({ providerId }) => {
  const firebaseUser = getFirebaseUser();

  return useMemo(() => {
    if (!firebaseUser) return false;
    const profiles = firebaseUser.providerData.filter((data) => data?.providerId === providerId);
    return profiles.length > 0;
  }, [firebaseUser, providerId]);
};

export default useIsOIDCCredential;
