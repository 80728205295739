/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyRecurringCalendarTypename = "PharmacyRecurringCalendar" as const;

/**
 *
 * @export
 * @interface PharmacyRecurringCalendar
 */
export interface PharmacyRecurringCalendar {
  /**
   *
   * @type {boolean}
   * @memberof PharmacyRecurringCalendar
   */
  closedAll?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyRecurringCalendar
   */
  hours?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof PharmacyRecurringCalendar
   */
  weekNumber: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyRecurringCalendar
   */
  weekday: PharmacyRecurringCalendarWeekdayEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyRecurringCalendar
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyRecurringCalendarWeekdayEnum {
  Sun = "Sun",
  Mon = "Mon",
  Tue = "Tue",
  Wed = "Wed",
  Thu = "Thu",
  Fri = "Fri",
  Sat = "Sat",
}

export function PharmacyRecurringCalendarFromJSON(json: any): PharmacyRecurringCalendar {
  return PharmacyRecurringCalendarFromJSONTyped(json, false);
}

export function PharmacyRecurringCalendarFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyRecurringCalendar {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyRecurringCalendarTypename,

    closedAll: !exists(json, "closed_all") ? undefined : json["closed_all"],
    hours: !exists(json, "hours") ? undefined : json["hours"],
    weekNumber: json["week_number"],
    weekday: json["weekday"],
  };
}

export function PharmacyRecurringCalendarToJSON(object?: PharmacyRecurringCalendar | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    closed_all: value.closedAll,
    hours: value.hours,
    week_number: value.weekNumber,
    weekday: value.weekday,
  };
}
