/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX bff-patient API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PostMCCMJAHISSyncJobRequestBodyTypename = "PostMCCMJAHISSyncJobRequestBody" as const;

/**
 *
 * @export
 * @interface PostMCCMJAHISSyncJobRequestBody
 */
export interface PostMCCMJAHISSyncJobRequestBody {
  /**
   *
   * @type {string}
   * @memberof PostMCCMJAHISSyncJobRequestBody
   */
  dateOfBirth?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostMCCMJAHISSyncJobRequestBody
   */
  readonly __typename?: string;
}

export function PostMCCMJAHISSyncJobRequestBodyFromJSON(json: any): PostMCCMJAHISSyncJobRequestBody {
  return PostMCCMJAHISSyncJobRequestBodyFromJSONTyped(json, false);
}

export function PostMCCMJAHISSyncJobRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostMCCMJAHISSyncJobRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostMCCMJAHISSyncJobRequestBodyTypename,

    dateOfBirth: !exists(json, "date_of_birth") ? undefined : json["date_of_birth"],
  };
}

export function PostMCCMJAHISSyncJobRequestBodyToJSON(object?: PostMCCMJAHISSyncJobRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date_of_birth: value.dateOfBirth,
  };
}
