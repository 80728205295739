/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  GetLinkedPatientResponse,
  GetLinkedPatientResponseFromJSON,
  GetLinkedPatientResponseToJSON,
  PatchLinkedPatientEmailRequestBody,
  PatchLinkedPatientEmailRequestBodyFromJSON,
  PatchLinkedPatientEmailRequestBodyToJSON,
  PatientCustomBadRequest1,
  PatientCustomBadRequest1FromJSON,
  PatientCustomBadRequest1ToJSON,
  PatientCustomBadRequest3,
  PatientCustomBadRequest3FromJSON,
  PatientCustomBadRequest3ToJSON,
  PatientPatient,
  PatientPatientFromJSON,
  PatientPatientToJSON,
  PostLinkPatientAuthenticationRequestBody,
  PostLinkPatientAuthenticationRequestBodyFromJSON,
  PostLinkPatientAuthenticationRequestBodyToJSON,
  PostLinkPatientAuthenticationResponse,
  PostLinkPatientAuthenticationResponseFromJSON,
  PostLinkPatientAuthenticationResponseToJSON,
  PostLinkedPatientRequestBody,
  PostLinkedPatientRequestBodyFromJSON,
  PostLinkedPatientRequestBodyToJSON,
  PostLinkedPatientResponse,
  PostLinkedPatientResponseFromJSON,
  PostLinkedPatientResponseToJSON,
  PutLinkedPatientNotificationAuthorizationRequestBody,
  PutLinkedPatientNotificationAuthorizationRequestBodyFromJSON,
  PutLinkedPatientNotificationAuthorizationRequestBodyToJSON,
} from "../models";

export interface GetLinkedPatientRequest {
  linkUserToken: string;
}

export interface PatchLinkedPatientEmailRequest {
  patchLinkedPatientEmailRequestBody: PatchLinkedPatientEmailRequestBody;
}

export interface PostLinkedPatientAuthenticationsRequest {
  postLinkPatientAuthenticationRequestBody: PostLinkPatientAuthenticationRequestBody;
  state?: string;
  state2?: string;
  nonce?: string;
}

export interface PostLinkedPatientsRequest {
  postLinkedPatientRequestBody: PostLinkedPatientRequestBody;
  state?: string;
  state2?: string;
  nonce?: string;
}

export interface PutLinkedPatientNotificationAuthorizationRequest {
  putLinkedPatientNotificationAuthorizationRequestBody: PutLinkedPatientNotificationAuthorizationRequestBody;
}

/**
 *
 */
export class LinkedPatientApi extends runtime.BaseAPI {
  /**
   * Delete external service user link from patients using JWT
   */
  async deleteLinkedPatientRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/linked-patient`,
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete external service user link from patients using JWT
   */
  async deleteLinkedPatient(initOverrides?: RequestInit): Promise<void> {
    await this.deleteLinkedPatientRaw(initOverrides);
  }

  /**
   * Get linked patient by link user token
   */
  async getLinkedPatientRaw(
    requestParameters: GetLinkedPatientRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetLinkedPatientResponse>> {
    if (requestParameters.linkUserToken === null || requestParameters.linkUserToken === undefined) {
      throw new runtime.RequiredError(
        "linkUserToken",
        "Required parameter requestParameters.linkUserToken was null or undefined when calling getLinkedPatient."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/linked-patients/{link_user_token}`.replace(
          `{${"link_user_token"}}`,
          encodeURIComponent(String(requestParameters.linkUserToken))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetLinkedPatientResponseFromJSON(jsonValue));
  }

  /**
   * Get linked patient by link user token
   */
  async getLinkedPatient(
    requestParameters: GetLinkedPatientRequest,
    initOverrides?: RequestInit
  ): Promise<GetLinkedPatientResponse> {
    return promiseRetry(
      (retry) =>
        this.getLinkedPatientRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update Email address and reregister email authentication user using JWT
   */
  async patchLinkedPatientEmailRaw(
    requestParameters: PatchLinkedPatientEmailRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientPatient>> {
    if (
      requestParameters.patchLinkedPatientEmailRequestBody === null ||
      requestParameters.patchLinkedPatientEmailRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "patchLinkedPatientEmailRequestBody",
        "Required parameter requestParameters.patchLinkedPatientEmailRequestBody was null or undefined when calling patchLinkedPatientEmail."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/linked-patient/email`,
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchLinkedPatientEmailRequestBodyToJSON(requestParameters.patchLinkedPatientEmailRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientPatientFromJSON(jsonValue));
  }

  /**
   * Update Email address and reregister email authentication user using JWT
   */
  async patchLinkedPatientEmail(
    requestParameters: PatchLinkedPatientEmailRequest,
    initOverrides?: RequestInit
  ): Promise<PatientPatient> {
    return promiseRetry(
      (retry) =>
        this.patchLinkedPatientEmailRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Authenticate using external service user credentials
   */
  async postLinkedPatientAuthenticationsRaw(
    requestParameters: PostLinkedPatientAuthenticationsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PostLinkPatientAuthenticationResponse>> {
    if (
      requestParameters.postLinkPatientAuthenticationRequestBody === null ||
      requestParameters.postLinkPatientAuthenticationRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postLinkPatientAuthenticationRequestBody",
        "Required parameter requestParameters.postLinkPatientAuthenticationRequestBody was null or undefined when calling postLinkedPatientAuthentications."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.state !== undefined) {
      queryParameters["state"] = requestParameters.state;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/link-patient-authentications`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostLinkPatientAuthenticationRequestBodyToJSON(
          requestParameters.postLinkPatientAuthenticationRequestBody
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PostLinkPatientAuthenticationResponseFromJSON(jsonValue)
    );
  }

  /**
   * Authenticate using external service user credentials
   */
  async postLinkedPatientAuthentications(
    requestParameters: PostLinkedPatientAuthenticationsRequest,
    initOverrides?: RequestInit
  ): Promise<PostLinkPatientAuthenticationResponse> {
    return promiseRetry(
      (retry) =>
        this.postLinkedPatientAuthenticationsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Link external service user credentials to new or existing patients
   */
  async postLinkedPatientsRaw(
    requestParameters: PostLinkedPatientsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PostLinkedPatientResponse>> {
    if (
      requestParameters.postLinkedPatientRequestBody === null ||
      requestParameters.postLinkedPatientRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postLinkedPatientRequestBody",
        "Required parameter requestParameters.postLinkedPatientRequestBody was null or undefined when calling postLinkedPatients."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.state !== undefined) {
      queryParameters["state"] = requestParameters.state;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/linked-patients`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostLinkedPatientRequestBodyToJSON(requestParameters.postLinkedPatientRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PostLinkedPatientResponseFromJSON(jsonValue));
  }

  /**
   * Link external service user credentials to new or existing patients
   */
  async postLinkedPatients(
    requestParameters: PostLinkedPatientsRequest,
    initOverrides?: RequestInit
  ): Promise<PostLinkedPatientResponse> {
    return promiseRetry(
      (retry) =>
        this.postLinkedPatientsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Refresh external service user link information (append or delete) using JWT
   */
  async putLinkedPatientRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/linked-patient`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Refresh external service user link information (append or delete) using JWT
   */
  async putLinkedPatient(initOverrides?: RequestInit): Promise<void> {
    await this.putLinkedPatientRaw(initOverrides);
  }

  /**
   * Update notification provided access token using JWT or RequestBody
   */
  async putLinkedPatientNotificationAuthorizationRaw(
    requestParameters: PutLinkedPatientNotificationAuthorizationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientPatient>> {
    if (
      requestParameters.putLinkedPatientNotificationAuthorizationRequestBody === null ||
      requestParameters.putLinkedPatientNotificationAuthorizationRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putLinkedPatientNotificationAuthorizationRequestBody",
        "Required parameter requestParameters.putLinkedPatientNotificationAuthorizationRequestBody was null or undefined when calling putLinkedPatientNotificationAuthorization."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/linked-patient/notification-authorization`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutLinkedPatientNotificationAuthorizationRequestBodyToJSON(
          requestParameters.putLinkedPatientNotificationAuthorizationRequestBody
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientPatientFromJSON(jsonValue));
  }

  /**
   * Update notification provided access token using JWT or RequestBody
   */
  async putLinkedPatientNotificationAuthorization(
    requestParameters: PutLinkedPatientNotificationAuthorizationRequest,
    initOverrides?: RequestInit
  ): Promise<PatientPatient> {
    return promiseRetry(
      (retry) =>
        this.putLinkedPatientNotificationAuthorizationRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
