import { AuthError } from "firebase/auth";

export type IsFirebaseError = (error: AuthError | any) => boolean;

export const isFirebaseError: IsFirebaseError = (error) => {
  if ("code" in error) {
    if (typeof error.code === "string" && error.code.startsWith("auth/")) {
      return true;
    }
  }

  return false;
};
