// extracted by mini-css-extract-plugin
export var checked = "InputRadio-module--checked--d85f2";
export var checkmark = "InputRadio-module--checkmark--dabec";
export var completed = "InputRadio-module--completed--49989";
export var container = "InputRadio-module--container--7c789";
export var disableActive = "InputRadio-module--disableActive--b4932";
export var disabled = "InputRadio-module--disabled--c129d";
export var indicator = "InputRadio-module--indicator--703b2";
export var input = "InputRadio-module--input--30e7c";
export var label = "InputRadio-module--label--04d87";
export var nowrap = "InputRadio-module--nowrap--0b3aa";
export var radio = "InputRadio-module--radio--97e39";
export var widePadding = "InputRadio-module--widePadding--4e83d";