/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientServiceTermTypename = "PatientServiceTerm" as const;

/**
 *
 * @export
 * @interface PatientServiceTerm
 */
export interface PatientServiceTerm {
  /**
   *
   * @type {string}
   * @memberof PatientServiceTerm
   */
  revision?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientServiceTerm
   */
  readonly __typename?: string;
}

export function PatientServiceTermFromJSON(json: any): PatientServiceTerm {
  return PatientServiceTermFromJSONTyped(json, false);
}

export function PatientServiceTermFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientServiceTerm {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientServiceTermTypename,

    revision: !exists(json, "revision") ? undefined : json["revision"],
  };
}

export function PatientServiceTermToJSON(object?: PatientServiceTerm | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    revision: value.revision,
  };
}
