import { PatientServiceTerm } from "@mgdx/api/lib/patient/patient";
import { patientServiceTermApi } from "@mgdx/api/lib/patientServiceTermApi";
import { errorHandlerReport } from "@mgdx-libs/error-handler";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { patientServiceTermKeys } from "../../factories";

type ApiResponseData = PatientServiceTerm;
type ApiError = Error;
type QueryResponseData = PatientServiceTerm;

type QueryOptions = Omit<
  UseQueryOptions<ApiResponseData, ApiError, QueryResponseData>,
  "queryKey" | "queryFn" | "onError"
>;

export const useGetServiceTermsQuery = (options?: QueryOptions) =>
  useQuery<ApiResponseData, ApiError, QueryResponseData>({
    queryKey: patientServiceTermKeys.serviceTerm.current.queryKey,
    queryFn: () => patientServiceTermApi.getServiceTerms({}),
    onError: (error) => {
      errorHandlerReport(error);
    },
    ...options,
  });
