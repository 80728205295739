import { getAccessToken } from "@mgdx-libs/firebase";

import { Configuration, PatientApi } from "./bff/patient";
import { createFetchTimeout } from "./createFetchTimeout";
import requestHeaders from "./requestHeaders";

const bffPatientApi = new PatientApi(
  new Configuration({
    basePath: process.env.MGDX_API_BFF_PATIENT_URL,
    credentials: "include",
    accessToken: getAccessToken,
    headers: requestHeaders,
    fetchApi: createFetchTimeout(60000),
  })
);

export default bffPatientApi;
