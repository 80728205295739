import FullscreenDialog from "@mgdx/ui/components/FullscreenDialog";
import React, { FC, useMemo } from "react";

import useCurrentUser from "../../hooks/useCurrentUser";
import useDeleteUser from "../../hooks/useDeleteUser";
import useIsOIDCCredential from "../../hooks/useIsOIDCCredential";
import {
  AgreementNewTermsWithdrawView,
  AgreementNewTermsWithdrawViewProps,
} from "../../views/AgreementNewTermsWithdrawView";

export const AgreementNewTermsWithdrawDialog: FC<Omit<AgreementNewTermsWithdrawViewProps, "deleteUser">> = ({
  onClickShowReason,
}) => {
  const currentUser = useCurrentUser();
  const isOIDCCredential = useIsOIDCCredential({ providerId: "oidc.satudora" });
  const linkType = useMemo(() => {
    if (currentUser.isLiffLogined) return "liff";
    if (isOIDCCredential) return "oidc";

    return "";
  }, [currentUser.isLiffLogined, isOIDCCredential]);
  const deleteUser = useDeleteUser({ linkType });

  return (
    <FullscreenDialog isOpen={true} className="!bg-bg_all">
      <AgreementNewTermsWithdrawView deleteUser={deleteUser} onClickShowReason={onClickShowReason} />
    </FullscreenDialog>
  );
};
