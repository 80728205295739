import clsx from "clsx";
import React from "react";

import * as styles from "./Heading.module.css";

export type HeadingProps = {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  textSize?: "xm" | "xxm" | "l";
  textColor?: "default" | "weak";
  textAlign?: "left" | "center";
  withPaddingBottom?: boolean;
  children?: React.ReactNode;
  className?: string;
};

export const Heading: React.FC<HeadingProps> = ({
  level,
  textSize = "l",
  textColor = "default",
  textAlign = "left",
  withPaddingBottom = false,
  children,
  className,
}) => {
  const HTag = `h${level}` as "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

  return (
    <HTag
      className={clsx(
        styles.container,
        textSize === "xm" && styles.xm,
        textSize === "xxm" && styles.xxm,
        textSize === "l" && styles.l,
        textColor === "default" && styles.colorDefault,
        textColor === "weak" && styles.colorWeak,
        textAlign === "left" && styles.left,
        textAlign === "center" && styles.center,
        withPaddingBottom && styles.bottom,
        className
      )}
    >
      {children}
    </HTag>
  );
};
