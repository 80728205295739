import * as httpStatus from "http-status";

import {
  ApiBadRequestError,
  ApiCustomError,
  ApiError,
  ApiForbiddenError,
  ApiInternalServerError,
  ApiNotFoundError,
  ApiTimeoutError,
  ApiUnauthorizedError,
  ApiUnprocessableEntityError,
} from "../errors";
import { responseToErrorMessage } from "../utils/responseToErrorMessage";
import { parseErrorResponse } from "./checkErrorResponse";

export type ApiNetworkErrorHandler = (errorOrResponse: Error | Response | DOMException) => Promise<Error>;

export const apiErrorHandler: ApiNetworkErrorHandler = async (errorOrResponse) => {
  if (errorOrResponse instanceof DOMException) {
    return new ApiTimeoutError(errorOrResponse);
  }

  if (errorOrResponse instanceof Response) {
    const errorMessage = await responseToErrorMessage(errorOrResponse);

    if (errorOrResponse.status === httpStatus.BAD_REQUEST) {
      return new ApiBadRequestError(errorMessage);
    }

    if (errorOrResponse.status === httpStatus.UNAUTHORIZED) {
      return new ApiUnauthorizedError(errorMessage);
    }

    if (errorOrResponse.status === httpStatus.FORBIDDEN) {
      return new ApiForbiddenError(errorMessage);
    }

    if (errorOrResponse.status === httpStatus.NOT_FOUND) {
      return new ApiNotFoundError(errorMessage);
    }

    if (errorOrResponse.status === httpStatus.INTERNAL_SERVER_ERROR) {
      return new ApiInternalServerError(errorMessage);
    }

    if (errorOrResponse.status === httpStatus.UNPROCESSABLE_ENTITY) {
      return new ApiUnprocessableEntityError(errorMessage);
    }

    // other response
    return new ApiError(errorMessage);
  }

  return errorOrResponse;
};

export const apiResponseToError = async (error: Response | DOMException | Error): Promise<Error> => {
  if (error instanceof Error) {
    throw error;
  } else if (error instanceof DOMException) {
    // TODO: DOMException全てをタイムアウトにするのは正確ではないのでは？直したい
    return new ApiTimeoutError(error);
  } else {
    const { message, detail } = await parseErrorResponse(error);
    if (message) {
      return new ApiCustomError(message);
    } else {
      if (error.status === httpStatus.BAD_REQUEST) {
        return new ApiBadRequestError(detail);
      }

      if (error.status === httpStatus.UNAUTHORIZED) {
        return new ApiUnauthorizedError(detail);
      }

      if (error.status === httpStatus.FORBIDDEN) {
        return new ApiForbiddenError(detail);
      }

      if (error.status === httpStatus.NOT_FOUND) {
        return new ApiNotFoundError(detail);
      }

      if (error.status === httpStatus.INTERNAL_SERVER_ERROR) {
        return new ApiInternalServerError(detail);
      }

      if (error.status === httpStatus.UNPROCESSABLE_ENTITY) {
        return new ApiUnprocessableEntityError(detail);
      }

      // other response
      return new ApiError(detail);
    }
  }
};

export const SYSTEM_ERROR_MESSAGE = "システムエラーが発生しました。時間を置いてからお試しください。";
export const TIMEOUT_ERROR_MESSAGE = "ネットワークエラーが発生しました。通信環境をご確認ください。";

export const getApiNetworkErrorMessage = async (errorOrResponse: Error | Response | DOMException) => {
  const error = await apiErrorHandler(errorOrResponse);

  if (error instanceof ApiTimeoutError) {
    return TIMEOUT_ERROR_MESSAGE;
  } else {
    return SYSTEM_ERROR_MESSAGE;
  }
};
