import { toastError } from "@mgdx/ui/components/Toast";

import { ApiCustomError } from "../errors";
import { SYSTEM_ERROR_MESSAGE } from "./apiErrorHandler";

export const showErrorToast = (error: Error) => {
  if (error instanceof ApiCustomError) {
    toastError(error.message);
  } else {
    toastError(SYSTEM_ERROR_MESSAGE);
  }
};
