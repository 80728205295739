import { createQueryKeyStore } from "@lukemorales/query-key-factory";

import { MedicineNotebookAccountRequestConfig } from "../providers/MedicineNotebookAccountProvider";

export const medicineAlarmKeys = createQueryKeyStore({
  alarm: {
    detail: (
      requestConfig: MedicineNotebookAccountRequestConfig,
      date: string,
      dispenseRecordId?: number,
      otcMedicineId?: number
    ) => [requestConfig, date, dispenseRecordId, otcMedicineId],
    list: (chainId: number, familyAccountIds: number[]) => [chainId, familyAccountIds],
  },
});
