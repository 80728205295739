import clsx from "clsx";
import React, { Fragment } from "react";

import { InputRadio, InputRadioProps } from "../InputRadio";
import * as styles from "./ToggleInputGroup.module.css";
import { ToggleInputGroupBaseMin, ToggleInputGroupBaseMinProps } from "./ToggleInputGroupBaseMin";
import { RadioGroupOptionProps } from "./ToggleInputGroupRadio";

export type ToggleInputGroupRadioMinProps = Pick<InputRadioProps, "name" | "value" | "onChange"> &
  Pick<ToggleInputGroupBaseMinProps, "disabled" | "completed" | "error" | "message" | "noMessageArea" | "className"> & {
    radioGroupOptions: RadioGroupOptionProps[];
  };

export const ToggleInputGroupRadioMin: React.FC<ToggleInputGroupRadioMinProps> = ({
  /* ToggleInputGroupBaseMinProps */
  disabled,
  completed,
  error,
  message,
  noMessageArea,
  className,
  /* InputRadioProps */
  name,
  value,
  onChange,
  /* my props */
  radioGroupOptions,
}) => (
  <ToggleInputGroupBaseMin
    error={error}
    message={message}
    disabled={disabled}
    noMessageArea={noMessageArea}
    className={className}
  >
    {radioGroupOptions.map(({ label, value: optionValue, disabled: optionDisabled, ...radioProps }, index) => (
      <Fragment key={`item${index}`}>
        <InputRadio
          {...radioProps}
          onChange={onChange}
          name={name}
          value={optionValue}
          checked={optionValue === value}
          completed={completed}
          disabled={optionDisabled ?? disabled}
          widePadding={true}
          nowrap={false}
          className={clsx(styles.radio, index !== 0 && styles.divider, !disabled && optionDisabled && styles.disabled)}
        >
          {label}
        </InputRadio>
      </Fragment>
    ))}
  </ToggleInputGroupBaseMin>
);
