import isClient from "@mgdx/shared/src/utils/isClient";
import { logger } from "@mgdx-libs/logger";
import { useCallback, useEffect } from "react";

export const FOCUSED_CLASS_NAME = "app-field-focused" as const;

const OBSERVE_TAG_NAMES = ["INPUT", "TEXTAREA"];
const OBSERVE_TYPES = [
  null, // for textarea
  "text",
  "search",
  "tel",
  "url",
  "email",
  "password",
  "datetime",
  "date",
  "month",
  "week",
  "time",
  "datetime-local",
  "number",
  "color",
];

export const useFocusAndBlurObserver = () => {
  const onFocus = useCallback((event: FocusEvent) => {
    const element = event.target as HTMLElement;
    const tagName = element.tagName;
    const type = element.getAttribute("type");

    if (OBSERVE_TAG_NAMES.includes(tagName) && OBSERVE_TYPES.includes(type)) {
      window.document.body.classList.add(FOCUSED_CLASS_NAME);
      logger.debug("useFocusAndBlurObserver focus to <%s type=%s ...", tagName, type);
    }
  }, []);

  const onBlur = useCallback((event: FocusEvent) => {
    const element = event.target as HTMLElement;
    const tagName = element.tagName;
    const type = element.getAttribute("type");

    if (OBSERVE_TAG_NAMES.includes(tagName) && OBSERVE_TYPES.includes(type)) {
      window.document.body.classList.remove(FOCUSED_CLASS_NAME);
      logger.debug("useFocusAndBlurObserver blur to <%s type=%s ...", tagName, type);
    }
  }, []);

  useEffect(() => {
    if (!isClient()) return;

    window.document.body.addEventListener("focus", onFocus, true);
    window.document.body.addEventListener("blur", onBlur, true);

    return () => {
      window.document.body.removeEventListener("focus", onFocus, true);
      window.document.body.removeEventListener("blur", onBlur, true);
    };
  }, [onBlur, onFocus]);
};
