import clsx from "clsx";
import React, { Fragment } from "react";

import { InputCheckbox } from "../InputCheckbox";
import * as styles from "./ToggleInputGroup.module.css";
import { ToggleInputGroupBaseMin, ToggleInputGroupBaseMinProps } from "./ToggleInputGroupBaseMin";
import { CheckboxGroupOptionsProps } from "./ToggleInputGroupCheckbox";

export type ToggleInputGroupCheckboxMinProps = Pick<
  ToggleInputGroupBaseMinProps,
  "disabled" | "completed" | "error" | "message" | "noMessageArea" | "className"
> & {
  checkboxGroupOptions: CheckboxGroupOptionsProps[];
};

export const ToggleInputGroupCheckboxMin: React.FC<ToggleInputGroupCheckboxMinProps> = ({
  /* ToggleInputGroupBaseMinProps */
  disabled,
  completed,
  error,
  message,
  noMessageArea,
  className,
  /* my props */
  checkboxGroupOptions,
  ...props
}) => (
  <ToggleInputGroupBaseMin
    error={error}
    message={message}
    disabled={disabled}
    noMessageArea={noMessageArea}
    className={className}
  >
    {checkboxGroupOptions.map(
      (
        { label: optionLabel, name, checked, value: optionValue, disabled: optionDisabled, onChange, ...option },
        index
      ) => (
        <Fragment key={`item${index}`}>
          <InputCheckbox
            {...props}
            onChange={onChange}
            name={name}
            value={optionValue}
            checked={checked}
            completed={completed}
            disabled={optionDisabled ?? disabled}
            widePadding={true}
            nowrap={false}
            className={clsx(
              styles.checkbox,
              index !== 0 && styles.divider,
              !disabled && optionDisabled && styles.disabled
            )}
            data-testid={option["data-testid"]}
          >
            {optionLabel}
          </InputCheckbox>
        </Fragment>
      )
    )}
  </ToggleInputGroupBaseMin>
);
