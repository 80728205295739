import Container from "@mgdx/ui/components/Container";
import { FilledButton } from "@mgdx/ui/components/FilledButton";
import { InputTextareaField } from "@mgdx/ui/components/InputTextareaField";
import { MessagePlain } from "@mgdx/ui/components/MessagePlain";
import { ToggleInputGroupCheckbox } from "@mgdx/ui/components/ToggleInputGroup";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";

import { DeleteUser } from "../../hooks/useDeleteUser";
import { PageTitle } from "../../ui/PageTitle";

const validationSchema = Yup.object().shape({
  reasons: Yup.array().of(Yup.string()),
  message: Yup.string().nullable(false),
});

export type Props = {
  deleteUser: DeleteUser;
};

export const checkboxLabels = {
  noUsablePharmacies: "利用したい医療機関/薬局がない",
  hardToUse: "サービスが使いづらい",
  notCorrectlyWorking: "正常に動作しなかった",
};

export const AccountDeleteView = ({ deleteUser }: Props) => {
  const formik = useFormik({
    initialValues: {
      reasons: [],
      message: "",
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const { reasons, message } = values;
      await deleteUser({ reasons, message });

      setSubmitting(false);
    },
  });

  return (
    <Container>
      <PageTitle>退会の確認</PageTitle>

      <MessagePlain variant="info" className="mt-2">
        薬急便を退会してよろしいですか ?
      </MessagePlain>

      <form id="AccountDeleteView-form" onSubmit={formik.handleSubmit} className="flex flex-col items-center">
        <ToggleInputGroupCheckbox
          name="reasons"
          label="退会理由(複数可)"
          required={false}
          noMessageArea={true}
          disabled={formik.isSubmitting}
          onChange={formik.handleChange}
          checkboxGroupOptions={[
            {
              label: "利用したい医療機関/薬局がない",
              value: "利用したい医療機関/薬局がない",
              "data-testid": "account-delete-reasons-no-usable-pharmacies",
            },
            {
              label: "サービスが使いづらい",
              value: "サービスが使いづらい",
              "data-testid": "account-delete-reasons-hard-to-use",
            },
            {
              label: "正常に動作しなかった",
              value: "正常に動作しなかった",
              "data-testid": "account-delete-reasons-not-correctly-working",
            },
          ]}
          className="mt-6"
          message={formik.errors.reasons}
          data-testid="account-delete-reasons"
        />

        <InputTextareaField
          name="message"
          label="その他・ご意見・ご感想"
          placeholder="サービスの改善に日々努めてまいりますので、ご意見・ご感想の入力をお願いします。"
          required={false}
          message={formik.errors.message}
          value={formik.values.message}
          onChange={formik.handleChange}
          disabled={formik.isSubmitting}
          error={!!formik.errors.message}
          rows={4}
          className="mt-4"
          data-testid="account-delete-message"
        />

        <FilledButton
          type="submit"
          variant="secondary"
          loading={formik.isSubmitting}
          disabled={formik.isSubmitting}
          widthType="full-width"
          className="mt-2"
          data-testid="account-delete-submit"
        >
          薬急便を退会する
        </FilledButton>
      </form>
    </Container>
  );
};
